import { Box, HStack } from "@@panda/jsx";
import React from "react";
import { useController, useFormContext } from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";
import { Switch } from "./Switch";

type FormSwitchProps = {
  name: string;
  label: string;
  subLabel?: string | React.ReactNode;
  isDisabled?: boolean;
};

export function FormSwitch({
  name,
  label,
  subLabel,
  isDisabled = false,
}: FormSwitchProps) {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { field } = useController({
    name,
    control,
  });

  const withError = Boolean(errors[name]);

  return (
    <Box w="full">
      <HStack justifyContent="flex-start" alignItems="center">
        <FormLabel htmlFor={name} position="relative" top="xs">
          {label}
        </FormLabel>

        <Switch
          id={name}
          onChange={field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          name={field.name}
          inputRef={field.ref}
          isDisabled={isDisabled}
        />
      </HStack>

      {subLabel ? <FormSubLabel mt="0.5rem">{subLabel}</FormSubLabel> : null}

      {withError ? (
        <FormError id={`${name}-error`}>
          {getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
