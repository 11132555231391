/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * This describes how the valuation cap is applied. There are only two
options here: pre or post. Which refers to the next funding round.
https://www.rennoco.com/post/pre-money-versus-post-money-what-valuation-cap-is-best-for-you

 */
export type ValuationCapType =
  (typeof ValuationCapType)[keyof typeof ValuationCapType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ValuationCapType = {
  none: "none",
  pre: "pre",
  post: "post",
} as const;
