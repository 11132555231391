import { createContext, useContext } from "react";

import { Variant } from "./utils";

export interface AlertContextShape {
  variant: Variant;
}

export const AlertContext = createContext<AlertContextShape>({
  variant: "info",
});

export function useAlertContext() {
  const result = useContext(AlertContext);
  return result;
}
