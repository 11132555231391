import { useState } from "react";
import { useAuth } from "services/auth/AuthProvider";

import { isTestingAccount } from "./utils";

export function useThumbnail() {
  const { account } = useAuth();
  const [loadError, setLoadError] = useState(false);

  function handleFailedLoad() {
    setLoadError(true);
  }

  return {
    loadError,
    isTestAccount: isTestingAccount(`${account?.email}`),
    handleFailedLoad,
  };
}
