class Polyfill extends EventTarget {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _onchange: any;
}

if (typeof window !== "undefined" && "onorientationchange" in window) {
  // Safari 15
  const weakMap = new WeakMap();
  const ScreenOrientation = class ScreenOrientation extends Polyfill {
    constructor() {
      super();
      this._onchange = null;
      Object.defineProperties(this, {
        _onchange: { enumerable: false },
      });
    }
    get angle() {
      let angle = window.orientation;
      if (angle < 0) angle += 360;
      return angle;
    }
    get type() {
      const angle = this.angle;
      switch (angle) {
        case 0:
          return "portrait-primary";
        case 90:
          return "landscape-primary";
        case 180:
          return "portrait-secondary";
        case 270:
          return "landscape-secondary";
        default:
          return "";
      }
    }
    async lock() {}
    async unlock() {}
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override addEventListener(type: any, listener: any) {
      if (type === "change") {
        const middleListener = () => {
          this.dispatchEvent(new Event("change"));
        };
        weakMap.set(listener, middleListener);
        window.addEventListener("orientationchange", middleListener);
      }
      super.addEventListener(type, listener);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    override removeEventListener(type: any, listener: any) {
      if (type === "change") {
        const middleListener = weakMap.get(listener);
        if (middleListener) {
          window.removeEventListener("orientationchange", middleListener);
        }
      }
      super.removeEventListener(type, listener);
    }
    set onchange(handler) {
      const oldHandler = this._onchange;
      if (handler === oldHandler) return;
      if (oldHandler !== null) {
        this.removeEventListener("change", oldHandler);
        this._onchange = null;
      }
      if (typeof handler === "function") {
        this.addEventListener("change", handler);
        this._onchange = handler;
      }
    }
    get onchange() {
      return this._onchange;
    }
  };
  Object.defineProperty(ScreenOrientation.prototype, Symbol.toStringTag, {
    enumerable: false,
    configurable: true,
    writable: false,
    value: "ScreenOrientation",
  });
}

function polyfill() {
  if (typeof window !== "undefined" && !window.screen.orientation) {
    if (!ScreenOrientation) {
      throw new Error("cannot polyfill screen.orientation");
    }
    const orientation = new ScreenOrientation();
    Object.defineProperty(Screen.prototype, "orientation", {
      enumerable: true,
      configurable: true,
      get: function getOrientation() {
        return orientation;
      },
    });
    Object.defineProperty(window, "ScreenOrientation", {
      enumerable: false,
      configurable: true,
      writable: true,
      value: ScreenOrientation,
    });
  }
}

polyfill();
