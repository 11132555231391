/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Categorisation required indicates that the account holder hasn't
completed the categorisation quiz.

 */
export type AccountStatusCategorisationRequired =
  (typeof AccountStatusCategorisationRequired)[keyof typeof AccountStatusCategorisationRequired];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusCategorisationRequired = {
  categorisation_required: "categorisation_required",
} as const;
