import { Box } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";
import { ComponentProps } from "react";

import { Form } from "../Form";
import { useFormURLEmbed } from "./useFormURLEmbed";

type Props = ComponentProps<typeof Form.URLField>;

export function FormURLEmbed({ name, label, ...restOfUrlFieldProps }: Props) {
  const { url, isLgScreenUp } = useFormURLEmbed({ name });

  return (
    <Box w="full">
      <Form.URLField name={name} label={label} {...restOfUrlFieldProps} />
      {url ? (
        isLgScreenUp ? (
          <>
            <Typography variant="subtext" color="grey.gunsmoke" my="m">
              Preview:{" "}
            </Typography>

            <iframe
              title="Deck preview"
              width="100%"
              height="400px"
              src={url}
              data-testid="deck-embed-iframe"
            />
          </>
        ) : (
          <Dialog title="Deck embed preview">
            <Dialog.Trigger>
              <Button variant="secondary" mt="s">
                Preview
              </Button>
            </Dialog.Trigger>
            <Dialog.Content>
              <iframe
                title="Deck preview"
                width="100%"
                height="400px"
                src={url}
                data-testid="deck-embed-iframe"
              />
            </Dialog.Content>
          </Dialog>
        )
      ) : null}
    </Box>
  );
}
