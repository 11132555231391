import { Box, BoxProps } from "@@panda/jsx";
import {
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  ComboboxProvider,
} from "@ariakit/react/combobox";
import {
  Control,
  Controller,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { getFormFieldError } from "../helpers";
import { comboBoxRecipe } from "./styles";
import { useFormCombobox } from "./useFormCombobox";

export interface ComboboxOption {
  value: string;
  label: string;
}

export interface FormComboboxProps extends BoxProps {
  name: string;
  label: string;
  options: ComboboxOption[];
  fieldOpts?: RegisterOptions;
  invalidErrorMessage?: string;
  initialValue?: string;
  placeholder?: string;
}

export function FormCombobox({
  name,
  label,
  initialValue: propInitialValue,
  fieldOpts,
  options,
  invalidErrorMessage,
  placeholder = "Type and select your option",
  ...rest
}: FormComboboxProps) {
  const {
    formState: { errors, defaultValues },
    setValue,
    setError,
    clearErrors,
    control,
    register,
  } = useFormContext();

  const rhfDefaultValue = defaultValues?.[name] as unknown;
  const initialValue = ((rhfDefaultValue || propInitialValue) ?? "") as string;
  const withError = Boolean(errors[name]);
  const styles = comboBoxRecipe();

  const { inputTerm, onSearchOption, onSelectOption, shouldOpen, results } =
    useFormCombobox({
      initialValue,
      errors,
      setError,
      setValue,
      clearErrors,
      options,
      name,
    });

  const { ref: _, ...registerProps } = { ...register(name, fieldOpts) };

  return (
    <Box w="full">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        control={control as Control}
        {...registerProps}
        name={name}
        render={() => (
          <Box {...rest} className={styles.wrap}>
            <ComboboxProvider>
              <Combobox
                name={name}
                id={name}
                placeholder={placeholder}
                onChange={(e) => onSearchOption(e.target.value)}
                aria-errormessage={`${name}-error`}
                className={styles.input}
                aria-label={label}
                value={inputTerm}
                {...(withError ? { "aria-invalid": "true" } : null)}
              />
              {shouldOpen ? (
                <ComboboxPopover
                  gutter={4}
                  sameWidth
                  className={styles.popover}
                >
                  {results.map((option) => (
                    <ComboboxItem
                      value={option.label}
                      key={option.value}
                      onClick={() => onSelectOption(option.label)}
                      className={styles.option}
                    >
                      {option.label}
                    </ComboboxItem>
                  ))}
                </ComboboxPopover>
              ) : null}
            </ComboboxProvider>
          </Box>
        )}
      />
      {withError ? (
        <FormError id={`${name}-error`}>
          {invalidErrorMessage || getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
