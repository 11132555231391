import {
  Account,
  AccountSignupParamsSignupIntention,
  AccountStatus,
} from "@internal/rest/generated/schemas";
import { NextRouter } from "next/router";

import { OnboardingStepPaths } from "./steps";

export async function goToStep(router: NextRouter, step: OnboardingStepPaths) {
  // step is the slug & we don't want to include it
  const { step: _, ...params } = router.query;

  await router.push({
    pathname: `/onboarding/${step}`,
    query: params,
  });
}

export async function goToApp(router: NextRouter) {
  const returnUrl = router.query["returnUrl"] as string | undefined;

  if (returnUrl) {
    await router.push(returnUrl);
  } else {
    await router.push("/");
  }
}

export function computeNeedsOnboarding(account: Account | undefined) {
  return Boolean(computeTargetStep(account));
}

export function computeTargetStep(account: Account | undefined) {
  if (!account) return undefined;

  if (account.status === AccountStatus.password_required) {
    return OnboardingStepPaths.SetPassword;
  }

  if (account.status === AccountStatus.personal_details_required) {
    return OnboardingStepPaths.InvestorDetails;
  }

  if (isMissingSignupIntention(account.signup_params?.signup_intention)) {
    return OnboardingStepPaths.Intention;
  }

  if (account.status === AccountStatus.categorisation_required) {
    return OnboardingStepPaths.Categorisation;
  }

  if (account.status === AccountStatus.assessment_required) {
    return OnboardingStepPaths.Assessment;
  }

  if (
    account.status === AccountStatus.sophisticated_investor_declaration_required
  ) {
    return OnboardingStepPaths.Declaration;
  }

  return undefined;
}

function isMissingSignupIntention(intention: string | undefined) {
  return !Object.keys(AccountSignupParamsSignupIntention).includes(
    intention ?? ""
  );
}
