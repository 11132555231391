import { ClientCategorisationCategory } from "@internal/rest/generated/schemas";

export const CategoryTypes = {
  [ClientCategorisationCategory.self_certified]:
    ClientCategorisationCategory.self_certified,
  [ClientCategorisationCategory.high_net_worth]:
    ClientCategorisationCategory.high_net_worth,
  none: "none",
} as const;

export type CategoryType = keyof typeof CategoryTypes;

export const AssessmentMetaSteps = {
  list: "list",
  intro: "intro",
  failure: "failure",
} as const;

export type AssessmentState = keyof typeof AssessmentMetaSteps;

export const Steps = {
  ...CategoryTypes,
  ...AssessmentMetaSteps,
};

export type Step = keyof typeof Steps;
