/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The following statuses are non-ordered and upon an account completing
one set of requirements for any given status, their status may change to
any other status.

The outcome of an investment being `awaiting_payment` is that the
account holder making the investment may view the payment details which
frees them up to wire their funds.

 */
export type InvestmentStatus =
  (typeof InvestmentStatus)[keyof typeof InvestmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestmentStatus = {
  passed: "passed",
  commitment_required: "commitment_required",
  terms_acceptance_required: "terms_acceptance_required",
  payment_required: "payment_required",
  transfer_initiated: "transfer_initiated",
  payment_received: "payment_received",
  completed: "completed",
} as const;
