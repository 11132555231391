import { css, cx } from "@@panda/css";
import { styled } from "@@panda/jsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps, createContext, useEffect } from "react";
import {
  Control,
  FieldValues,
  FormProvider,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormProps,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetValue,
  useForm,
} from "react-hook-form";
import { z } from "zod";

import { FormAmountField } from "./FormAmountField/FormAmountField";
import { FormCheckboxGroup } from "./FormCheckboxGroup/FormCheckboxGroup";
import { FormCombobox } from "./FormCombobox/FormCombobox";
import { FormCountrySelect } from "./FormCountrySelect/FormCountrySelect";
import { FormDateField } from "./FormDateField/FormDateField";
import { FormHandleField } from "./FormHandleField/FormHandleField";
import { FormLabel } from "./FormLabel/FormLabel";
import { FormSelect } from "./FormSelect/FormSelect";
import { FormSignature } from "./FormSignature/FormSignature";
import { FormSubmit } from "./FormSubmit/FormSubmit";
import { FormSwitch } from "./FormSwitch/FormSwitch";
import { FormTextArea } from "./FormTextArea/FormTextArea";
import { FormTextField } from "./FormTextField/FormTextField";
import { FormURLEmbed } from "./FormURLEmbedField/FormURLEmbed";
import { FormUrlField } from "./FormUrlField/FormUrlField";

type Props<
  TSchema extends z.ZodTypeAny,
  ValidatedValues extends FieldValues = z.infer<TSchema>, // storing a type here as a helper
> = {
  children?: React.ReactNode;
  onSubmit: (
    values: ValidatedValues,
    reset?: UseFormReset<ValidatedValues>
  ) => void;
  config?: Omit<UseFormProps<ValidatedValues>, "resolver"> & {
    schema?: TSchema;
  };
  onFieldsChange?: (
    fields: string[] | null,
    clearErrors: UseFormClearErrors<ValidatedValues>,
    setValue: UseFormSetValue<ValidatedValues>,
    reset: UseFormReset<ValidatedValues>
  ) => void;
  fieldsToWatch?: string[];
  noValidate?: ComponentProps<"form">["noValidate"];
} & Omit<ComponentProps<typeof styled.form>, "onSubmit">;

Form.Select = FormSelect;
Form.CheckboxGroup = FormCheckboxGroup;
Form.Combobox = FormCombobox;
Form.AmountField = FormAmountField;
Form.FormLabel = FormLabel;
Form.CountrySelect = FormCountrySelect;
Form.TextArea = FormTextArea;
Form.TextField = FormTextField;
Form.URLField = FormUrlField;
Form.URLEmbed = FormURLEmbed;
Form.HandleField = FormHandleField;
Form.Signature = FormSignature;
Form.Submit = FormSubmit;
Form.DateField = FormDateField;
Form.Switch = FormSwitch;

interface FormContextShape {
  register: UseFormRegister<{ [key: string]: unknown }> | (() => undefined);
  errors: Record<string, unknown>;
  isSubmitting: boolean;
  setValue: UseFormSetValue<Record<string, unknown>>;
  setError: UseFormSetError<Record<string, unknown>>;
  clearErrors: UseFormClearErrors<Record<string, unknown>>;
  touchedFields: { [key: string]: unknown };
  isSubmitted: boolean;
  getValues: UseFormGetValues<FieldValues>;
  control: Control<FieldValues, Record<string, unknown>> | null;
}

export const FormContext = createContext<FormContextShape>({
  register: () => undefined,
  setValue: () => undefined,
  setError: () => undefined,
  clearErrors: () => undefined,
  errors: {},
  isSubmitting: false,
  touchedFields: {},
  isSubmitted: false,
  getValues: () => [],
  control: null,
});

export function Form<TSchema extends z.ZodTypeAny>({
  children,
  onSubmit,
  config = {},
  onFieldsChange,
  fieldsToWatch,
  className,
  ...rest
}: Props<TSchema>) {
  const formContext = useForm({
    ...config,
    ...(config.schema ? { resolver: zodResolver(config.schema) } : {}),
  });

  const { watch, clearErrors, setValue, handleSubmit, reset } = formContext;

  const fieldValues = onFieldsChange ? watch(fieldsToWatch || []) : null;

  useEffect(() => {
    onFieldsChange?.(fieldValues, clearErrors, setValue, reset);
  }, [fieldValues]);

  return (
    <FormProvider {...formContext}>
      <styled.form
        className={cx(
          css({
            "& fieldset[disabled] input, & fieldset[disabled] textarea": {
              bgColor: "#969595",
              borderColor: "#969595!",
              color: "grey.minecraft",
              cursor: "not-allowed",
            },
            "& fieldset[disabled] [data-testid='amount-field-symbol']": {
              borderColor: "grey.minecraft",
              opacity: 0.5,
              "& svg path": {
                stroke: "grey.minecraft",
              },
            },
            "& fieldset[disabled] label": {
              color: "grey.gunsmoke",
            },
          }),
          className
        )}
        onSubmit={handleSubmit((values) => {
          onSubmit(values, reset);
        })}
        {...rest}
      >
        {children}
      </styled.form>
    </FormProvider>
  );
}
