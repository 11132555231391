/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * This is the currency that the investee company wishes to receive,
and in which the round is priced.

 */
export type BaseCurrency = (typeof BaseCurrency)[keyof typeof BaseCurrency];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BaseCurrency = {
  gbp: "gbp",
  eur: "eur",
  usd: "usd",
  usdc: "usdc",
} as const;
