/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type DealMessageDirection =
  (typeof DealMessageDirection)[keyof typeof DealMessageDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealMessageDirection = {
  investor_to_creators: "investor_to_creators",
  creator_to_investors: "creator_to_investors",
  test_email_preview: "test_email_preview",
} as const;
