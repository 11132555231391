import { AssessmentFailure } from "./components/AssessmentFailure/AssessmentFailure";
import { CategorisationOverview } from "./components/CategorisationOverview/CategorisationOverview";
import { CategoryList } from "./components/CategoryList/CategoryList";
import { HighNetWorth } from "./components/HighNetWorth/HighNetWorth";
import { SelfCertified } from "./components/SelfCertified/SelfCertified";
import { ClientCategorizationContext } from "./context";
import { useCategorisationStep } from "./useCategorisationStep";
import { AssessmentMetaSteps, CategoryTypes } from "./utils";

export function CategorisationStep() {
  const { activeStep, setActiveStep } = useCategorisationStep();

  let View;

  switch (activeStep) {
    case AssessmentMetaSteps.failure:
      View = AssessmentFailure;
      break;
    case AssessmentMetaSteps.intro:
      View = CategorisationOverview;
      break;
    case CategoryTypes.self_certified:
      View = SelfCertified;
      break;
    case CategoryTypes.high_net_worth:
      View = HighNetWorth;
      break;
    case AssessmentMetaSteps.list:
    default:
      View = CategoryList;
      break;
  }

  return (
    <ClientCategorizationContext.Provider value={{ setActiveStep, activeStep }}>
      <View />
    </ClientCategorizationContext.Provider>
  );
}
