import { createContext, useContext } from "react";

import { Step } from "./helpers";

type Context = {
  setActiveStep: (step: Step) => void;
  activeStep: Step;
};

const InvestorAssessmentContext = createContext<Context>({
  setActiveStep: () => undefined,
  activeStep: "overview",
});

export const useInvestorAssessmentContext = () => {
  const context = useContext(InvestorAssessmentContext);
  if (context === undefined) {
    throw new Error(
      "useInvestorAssessmentContext must be used within a InvestorAssessmentContext"
    );
  }
  return context;
};

export const InvestorAssessmentContextProvider =
  InvestorAssessmentContext.Provider;
