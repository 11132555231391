import { BookOpenIcon } from "@heroicons/react/24/outline";
import { useAccountGetAppropriatenessAssessment } from "@internal/rest/generated/queries/accounts";
import { Styled } from "components/styled";
import { StepIntro } from "screens/onboarding/components/StepIntro";

import { useInvestorAssessmentContext } from "../../AssessmentStepContext";

export function AssessmentOverview() {
  const { setActiveStep } = useInvestorAssessmentContext();

  useAccountGetAppropriatenessAssessment(); // prefetch assessment info (questions + status)

  return (
    <StepIntro
      title="Investor Assessment"
      icon={<BookOpenIcon width="2rem" color="white" />}
      desc={
        <>
          Almost there! Before you invest, we need to make sure you understand
          the risks of investing. We do this via a short assessment.
          <br />
          <br />
          To confirm this, you will be asked a short series of questions.
          <br />
          <br />
          <Styled.p color="grey.alabaster" fontSize="md">
            You must answer all questions correctly in order to invest on Odin.
          </Styled.p>
        </>
      }
      onContinue={() => setActiveStep("questions")}
    />
  );
}
