import { cva } from "@@panda/css";

export const tooltipRecipe = cva({
  base: {
    maxW: "sm",
    zIndex: "modal",
    borderRadius: "md",
    fontWeight: "normal",
    animation: "fadein 300ms, scaleup 100ms",
  },
  variants: {
    variant: {
      primary: {
        bg: "grey.alabaster",
        color: "black",
      },
      secondary: {
        bg: "grey.minecraft",
        color: "white",
        boxShadow: "0px 2px 1.5rem #121212",
        fontWeight: "semibold",
      },
    },
    size: {
      xs: { p: "s", fontSize: "xs" },
      md: { py: "s", px: "m" },
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});
