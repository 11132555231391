import { validation } from "services/validation";

import {
  FormTextField,
  FormTextFieldProps,
} from "../FormTextField/FormTextField";

interface Props extends Omit<FormTextFieldProps, "isRequired"> {
  isRequired?: boolean;
}

export function FormUrlField({ isRequired = false, ...props }: Props) {
  return (
    <FormTextField
      placeholder="https://"
      fieldOpts={{
        validate: (url) => validation.validateUrl(url, isRequired),
      }}
      {...props}
    />
  );
}
