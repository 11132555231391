import { ReactElement, ReactNode } from "react";
import { z } from "zod";

export enum FormFields {
  Answer = "answer",
}

export interface FormValues {
  [FormFields.Answer]: string;
}

export interface Statement {
  text: string;
  subText?: ReactNode;
  question: string;
  placeholder: string;
  field: ReactElement;
  schema: z.ZodSchema;
}
