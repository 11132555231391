import { VStack } from "@@panda/jsx";
import {
  BanknotesIcon,
  BuildingLibraryIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/solid";
import { Typography } from "components/display/Typography/Typography";
import { StatusBanner } from "components/feedback/StatusBanner";
import { Button } from "components/main/Button";
import { StepWrap } from "screens/onboarding/components/StepWrap";

import { IntentionCard } from "./components/IntentionCard/IntentionCard";
import { useInvestorIntentionStep } from "./useInvestorIntentionStep";
import { computeIconColor } from "./utils";

export function InvestorIntentionStep() {
  const { onSelectIntent, onContinue, selected, isLoading, ready, error } =
    useInvestorIntentionStep();

  if (!ready) return <StatusBanner error={error} />;

  return (
    <StepWrap>
      <VStack mb="8" alignItems="center">
        <Typography variant="heading.2" textAlign="center">
          What would you like to do?
        </Typography>

        <Typography variant="body" color="grey.gunsmoke" textAlign="center">
          Help us understand how you’d like to use Odin.
        </Typography>

        {selected === "founder" ? (
          <Typography
            variant="subtext"
            color="yellow.vesuvius"
            textAlign="center"
            mt="m"
            w="80%"
          >
            Please note that Odin is not a crowdfunding platform and we do not
            currently match you with investors
          </Typography>
        ) : null}
      </VStack>

      <VStack gap="m">
        <IntentionCard
          title="I’m a Founder"
          data-testid="founder-card"
          onClick={() => onSelectIntent("founder")}
          icon={
            <BanknotesIcon
              color={computeIconColor(selected === "founder")}
              width="1rem"
            />
          }
          isSelected={selected === "founder"}
          data-selected={selected === "founder"}
        >
          Aggregate investors into a single entity on your cap table.
        </IntentionCard>
        <IntentionCard
          title="I’m a Syndicate Lead"
          data-testid="syndicate-lead-card"
          onClick={() => onSelectIntent("syndicate_lead")}
          icon={
            <BuildingLibraryIcon
              color={computeIconColor(selected === "syndicate_lead")}
              width="1rem"
            />
          }
          isSelected={selected === "syndicate_lead"}
          data-selected={selected === "syndicate_lead"}
        >
          Collect investments from your syndicate and deploy capital.
        </IntentionCard>
        <IntentionCard
          data-testid="investor-card"
          title="I'm an Investor"
          onClick={() => onSelectIntent("investor")}
          icon={
            <RocketLaunchIcon
              color={computeIconColor(selected === "investor")}
              width="1rem"
            />
          }
          isSelected={selected === "investor"}
          data-selected={selected === "investor"}
        >
          Invest into early stage companies.
        </IntentionCard>
      </VStack>
      <Button
        width="full"
        onClick={onContinue}
        mt="8"
        isDisabled={!selected}
        isLoading={isLoading}
      >
        Continue
      </Button>
    </StepWrap>
  );
}
