import { css, cva } from "@@panda/css";

const _disabled = {
  opacity: 0.5,
  cursor: "not-allowed !important",
  pointerEvents: "none",
};

export const loadingStyles = css.raw({
  color: "transparent !important",
  position: "relative",
  transition: "none",
  opacity: 0.9,
  "& .button-spinner": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
});

export const buttonRecipe = cva({
  base: {
    display: "inline-flex",
    flexDir: "row",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 500,
    lineHeight: "1",
    borderRadius: "md",
    borderWidth: "1px",
    borderStyle: "solid",
    transition: "background .2s ease-in-out",
    userSelect: "none",
    whiteSpace: "nowrap",
    minWidth: "1rem", // allows for equal spacing in flex containers
  },
  variants: {
    variant: {
      primary: {
        borderColor: "brand.krilin",
        bgColor: "brand.krilin",
        color: "grey.bunker",
        _disabled,
        _spinner: {
          color: "grey.bunker!",
        },
        _hover: {
          bg: "brand.yamcha",
          borderColor: "brand.krilin",
          _disabled: { bg: "brand.yamcha" },
        },
        _active: {
          bg: "brand.yamcha",
          borderColor: "brand.krilin",
        },
        _focus: {
          outline: "none",
          boxShadow: "none",
          borderColor: "transparent!",
        },
        _loading: {
          opacity: 1,
          bg: "brand.krilin",
          borderColor: "brand.krilin!",
          color: "grey.bunker",
          _hover: {
            bgColor: "brand.krilin",
            borderColor: "brand.krilin!",
            color: "grey.bunker",
          },
        },
      },
      secondary: {
        bgColor: "grey.minecraft",
        borderColor: "grey.dune !important",
        color: "white",
        _disabled,
        _hover: {
          borderColor: "grey.dune !important",
          bgColor: "grey.dune",
          _disabled: { bgColor: "grey.minecraft" },
        },
        _active: {
          bgColor: "grey.minecraft !important",
          borderColor: "grey.dune",
        },
        _spinner: {
          color: "white",
        },
        _loading: {
          opacity: 1,
          bg: "grey.minecraft",
          borderColor: "grey.dune !important",
          color: "white",
          _hover: {
            bg: "grey.minecraft",
            borderColor: "grey.dune !important",
            color: "white",
          },
        },
      },
      ["secondary-destructive"]: {
        borderColor: "grey.dune !important",
        bgColor: "grey.minecraft",
        color: "red.salmon",
        transition: "background .3s ease-in-out",
        _disabled,
        _hover: {
          bgColor: "grey.dune",
        },
        _active: {
          bgColor: "grey.dune",
        },
      },
      destructive: {
        borderColor: "red.jiren !important",
        bgColor: "red.jiren",
        bgGradient: "linear(to-b, #D33F3F, red.firebrick)",
        color: "white",
        transition: "background .3s ease-in-out",
        _disabled,
        _hover: {
          borderColor: "red.jiren !important",
          bg: "red.firebrick",
          _disabled: { bg: "linear(to-b, #D33F3F, red.firebrick)" },
        },
        _active: {
          borderColor: "red.jiren !important",
          bg: "red.firebrick",
        },
        _loading: {
          borderColor: "red.jiren !important",
          bg: "#DF4B4B",
          color: "white",
        },
      },
      link: {
        color: "brand.krilin",
        padding: "0!",
        fontWeight: 600,
        background: "transparent",
        border: "none!",
        _hover: {
          textDecoration: "none",
          background: "transparent",
          color: "brand.yamcha",
        },
        _disabled,
        _focus: {
          outline: "none",
          boxShadow: "none",
          background: "transparent",
        },
        _active: {
          color: "brand.yamcha",
          background: "transparent",
        },
      },
    },
    size: {
      sm: {
        px: "m",
        height: "2rem",
      },
      md: {
        px: "l",
        height: "2.5rem",
      },
    },
  },
  defaultVariants: {
    variant: "primary",
    size: "md",
  },
});
