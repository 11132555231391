import { css } from "@@panda/css";
import { Box, HTMLStyledProps, styled } from "@@panda/jsx";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { MergeProps } from "@internal/utils/types";
import { inputStyles } from "components/forms/Form/FormTextField/styles";
import { CSSProperties, forwardRef } from "react";
import { useQueryState } from "utility/hooks/useQueryState";

export type Size = "sm" | "md";

type CustomSelectProps = {
  size?: Size;
  defaultValue?: string;
  paramName?: string;
  useUrlState?: boolean;
  selectStyle?: CSSProperties;
  selectContainerStyle?: CSSProperties;
};

type SelectProps = MergeProps<
  Omit<HTMLStyledProps<"select">, "style" | "className">,
  CustomSelectProps
>;

export const Select = forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => {
    const {
      size,
      useUrlState,
      onChange,
      paramName,
      selectStyle,
      selectContainerStyle,
      defaultValue,
      ...rest
    } = props;

    const [urlStateValue, setUrlStateValue] = useQueryState(
      paramName ?? "filter",
      {
        fallback: defaultValue ?? "",
      }
    );

    return (
      <Box
        w="full"
        position="relative" // for the icon
        style={selectContainerStyle}
      >
        <styled.select
          ref={ref}
          style={selectStyle}
          className={css({
            ...inputStyles,
            width: "full",
            appearance: "none",
            pr: "2rem", // for the icon
            ...(size === "sm" ? { py: 0, height: "2rem" } : {}),
          })}
          //@ts-expect-error error here seems wrong to me, onChange is only overriden if useUrlState is present, which is what I want
          onChange={onChange}
          {...(useUrlState
            ? {
                value: urlStateValue,
                onChange: (e) => {
                  onChange?.(e);
                  void setUrlStateValue(e.target.value);
                },
              }
            : {
                defaultValue,
              })}
          {...rest}
        />

        <ChevronDownIcon
          className={css({
            position: "absolute",
            right: "0.5rem",
            top: "50%",
            transform: "translateY(-50%)",
            width: "1.2rem",
            color: "#838383",
            strokeWidth: "8px",
          })}
        />
      </Box>
    );
  }
);

Select.displayName = "Select";
