import { DefaultSeoProps } from "next-seo";
import { OpenGraphMedia } from "next-seo/lib/types";
import { ODIN_TWITTER_HANDLE } from "utility/consts";

const description = "Raise and deploy capital seamlessly.";
const url = "https://app.joinodin.com/";

const baseUrl = `https://${process.env["NEXT_PUBLIC_VERCEL_URL"]}`;

const images: OpenGraphMedia[] = [
  {
    height: 1260,
    width: 2400,
    url: baseUrl + "/etc/odin-banner-open-graph-default.png",
    type: "image/png",
    alt: "Odin",
  },
];

function getTags(): DefaultSeoProps {
  return {
    defaultTitle: "Odin",
    description,
    canonical: url,
    openGraph: {
      url: url,
      type: "website",
      locale: "en_GB",
      site_name: "Odin",
      title: "Odin",
      description,
      images,
    },
    twitter: {
      handle: ODIN_TWITTER_HANDLE,
      site: url,
      cardType: "summary_large_image",
    },
  };
}

// next-seo does not support twitter:image, so we need to add it manually
// https://github.com/garmeeh/next-seo/issues/14#issuecomment-1753533824
export function TwitterImageOG() {
  return (
    <meta
      property="twitter:image"
      content={baseUrl + "/etc/odin-banner-open-graph-default.png"}
    />
  );
}

export const seo = { getTags };
