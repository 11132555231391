import { Box, Flex } from "@@panda/jsx";
import { ErrorBoundary as HighlightErrorBoundary } from "@highlight-run/next/client";
import { deriveError } from "@internal/rest/client";
import { ErrorBanner } from "components/feedback/ErrorBanner/ErrorBanner";
import { Footer } from "components/site/Footer/Footer";
import type { NextPage } from "next";
import { PropsWithChildren, ReactNode } from "react";

import { Navigation } from "../Navigation/Navigation";
import { UnauthorizedLayout } from "./UnauthorizedLayout/UnauthorizedLayout";
import {
  LAYOUT_MAX_WIDTH,
  LayoutVariant,
  WIDE_LAYOUT_MAX_WIDTH,
  getLayoutProps,
} from "./utils";

export type ComponentWithLayout<
  P = PropsWithChildren<Record<string, unknown>>,
> = NextPage<P> & LayoutProps;

export * from "./utils";

export interface LayoutProps {
  children: ReactNode;
  variant?: LayoutVariant | undefined;
}

export function Layout({ children, variant = "wide" }: LayoutProps) {
  const layoutWidth =
    variant === "narrow" ? LAYOUT_MAX_WIDTH : WIDE_LAYOUT_MAX_WIDTH;

  if (variant === "unauthorized")
    return <UnauthorizedLayout>{children}</UnauthorizedLayout>;

  return (
    <Flex
      data-testid="layout-view"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box flexGrow="1">
        <Navigation />
        <Box position="relative" width="full" minH="100vh">
          <HighlightErrorBoundary
            showDialog={false}
            fallback={(e: unknown) => {
              const error = deriveError(e);
              return (
                <Box
                  {...getLayoutProps({
                    width: layoutWidth,
                  })}
                >
                  <ErrorBanner
                    fullError={error}
                    correlationId={error.correlation_id ?? ""}
                    userMsg={error.message ?? ""}
                  />
                </Box>
              );
            }}
          >
            {variant === "narrow" || variant === "wide" ? (
              <Box
                {...getLayoutProps({
                  width: layoutWidth,
                })}
              >
                {children}
              </Box>
            ) : variant === "full-width" ? (
              <Box marginX="auto">{children}</Box>
            ) : (
              <>{children}</>
            )}
          </HighlightErrorBoundary>
        </Box>
        <Box width="full">
          <Footer />
        </Box>
      </Box>
    </Flex>
  );
}
