import {
  useAccountsGetSelf,
  useAccountsUpdateSelf,
} from "@internal/rest/generated/queries/accounts";
import { AccountSignupParamsSignupIntention } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { handleResourceEffect } from "utility/async";

export type InvestorIntention = AccountSignupParamsSignupIntention;

export function useInvestorIntentionStep() {
  const queryClient = useQueryClient();
  const [selected, setSelected] = useState<InvestorIntention>();
  const [isRedirecting, setRedirecting] = useState(false);
  const accountResult = useAccountsGetSelf();
  const { mutateAsync: updateIntention } = useAccountsUpdateSelf();

  function onSelectIntent(intent: InvestorIntention) {
    setSelected(intent);
  }

  async function onContinue() {
    await handleResourceEffect({
      async action() {
        setRedirecting(true);

        await updateIntention({
          data: {
            signup_params: {
              // SafeCast: The "Continue" button is only shown when the user has
              //            selected at least one option
              signup_intention: selected as InvestorIntention,
            },
          },
        });

        await queryClient.invalidateQueries({
          queryKey: accountResult.queryKey,
        });
      },
      error: {
        title: "Update Account Error",
      },
    });
  }

  return {
    ready: accountResult.isSuccess,
    error: accountResult.error,
    selected,
    onContinue,
    onSelectIntent,
    isLoading: isRedirecting,
  };
}
