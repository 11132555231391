import { getUrlFieldFormSchema } from "utility/forms";

function getPhoneRegex() {
  return new RegExp(/^([0|+[0-9]{1,5})?([0-9]{10})$/g);
}

function validateUrl(val: string, required: boolean) {
  const schema = getUrlFieldFormSchema({ required });
  const result = schema.safeParse(val);

  if (!result.success) return "Please insert a valid URL";

  return true;
}

export const validation = {
  getPhoneRegex,
  validateUrl,
};
