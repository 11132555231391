import { environment } from "services/environment";

const isTest = environment.getNodeEnv() === "test";

export function convertRemToPixels(rem: string) {
  if (!rem || isTest || typeof window === "undefined") return 0;
  return (
    parseFloat(rem) *
    parseFloat(getComputedStyle(document.documentElement).fontSize)
  );
}
