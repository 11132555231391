import { exhaustiveCheck } from "@internal/utils/types";

import { InvestorAssessmentContextProvider } from "./AssessmentStepContext";
import { AssessmentOverview } from "./components/AssessmentOverview/AssessmentOverview";
import { AssessmentQuestions } from "./components/AssessmentQuestions/AssessmentQuestions";
import { useAssessmentStep } from "./useAssesmentStep";

export function AssessmentStep() {
  const { activeStep, setActiveStep } = useAssessmentStep();

  let View: () => React.ReactElement;

  switch (activeStep) {
    case "overview":
      View = AssessmentOverview;
      break;

    case "questions":
      View = AssessmentQuestions;
      break;

    default:
      exhaustiveCheck(activeStep);
      View = AssessmentOverview;
      break;
  }

  return (
    <InvestorAssessmentContextProvider value={{ setActiveStep, activeStep }}>
      <View />
    </InvestorAssessmentContextProvider>
  );
}
