import { decodeJwt } from "jose";
import Script, { ScriptProps } from "next/script";
import { z } from "zod";

declare global {
  interface Window {
    zE: ((...args: unknown[]) => void) | undefined;
  }
}

export const zendesk = {
  scriptId: "ze-snippet",
  logoutUser: () => {
    if (window.zE) window.zE("messenger", "logoutUser");

    const script = document.getElementById(zendesk.scriptId);
    if (script) {
      script?.parentNode?.removeChild(script);
    }
  },
  verifyUser: async () => {
    if (window.zE) {
      const jwt = await fetch("/api/zendeskjwt");
      const jwtJson = await jwt.json();

      const schema = z.object({ token: z.string() });

      const { token } = schema.parse(jwtJson);

      return { decodedJwt: decodeJwt(token), jwt: token };
    }
    return null;
  },
  loginUser: (jwt: string) => {
    if (window.zE)
      window.zE("messenger", "loginUser", (callback: (jwt: string) => void) => {
        callback(jwt);
      });
  },
  toggleChatWindow: () => {
    if (window.zE) {
      const isWidgetClosed =
        sessionStorage.getItem("ZD-widgetOpen") === "false";

      window.zE("messenger", isWidgetClosed ? "open" : "close");
    }
  },
  onUnreadMessagesChange: (callback: (count: number) => void) => {
    if (window.zE) {
      window.zE("messenger:on", "unreadMessages", callback);
    }
  },
  Script: (props: Pick<ScriptProps, "onReady">) => (
    <Script
      strategy="afterInteractive"
      id={zendesk.scriptId}
      src="https://static.zdassets.com/ekr/snippet.js?key=c4c06f38-62aa-4f1a-a413-57797e713677"
      {...props}
    />
  ),
  injectCustomCloseButton: () => {
    const id = "zendesk-close";

    const alreadyExists = document.querySelector(`#${id}`);
    if (alreadyExists) return;

    const chatWindow = document.querySelector(
      'iframe[title="Messaging window"]'
    );

    const elToAdd = document.createElement("div");
    elToAdd.id = id;

    elToAdd.innerHTML = `<svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="13"
    viewBox="0 0 21 13"
    focusable="false"
    role="presentation"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="currentColor"
      d="M20.1005 2.7169L10.9931 11.8244C10.4724 12.3451 9.62815 12.3451 9.10745 11.8244L-8.00829e-06 2.7169L1.88561 0.831278L10.0503 8.99593L18.2149 0.831278L20.1005 2.7169Z"
    ></path>
  </svg>`;

    elToAdd.role = "button";
    elToAdd.style.position = "absolute";
    elToAdd.style.top = "7px";
    elToAdd.style.right = "7px";
    elToAdd.style.backgroundColor = "white";
    elToAdd.style.width = "50px";
    elToAdd.style.height = "50px";
    elToAdd.style.cursor = "pointer";
    elToAdd.style.display = "flex";
    elToAdd.style.justifyContent = "center";
    elToAdd.style.alignItems = "center";
    elToAdd.onclick = zendesk.toggleChatWindow;

    const insideFrame =
      //@ts-expect-error works 🤔
      chatWindow?.contentDocument || chatWindow?.contentWindow?.document;

    insideFrame?.body?.appendChild(elToAdd);
  },
};
