import { css, cx } from "@@panda/css";
import { Box, styled } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { inputRecipe } from "components/forms/Form/FormTextField/styles";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { FieldSymbol } from "../FieldSymbol/FieldSymbol";
import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { FormTextFieldProps } from "../FormTextField/FormTextField";
import { getFormFieldError } from "../helpers";

const resetStyles = css({
  "&::-webkit-calendar-picker-indicator": {
    display: "none",
    appearance: "none",
    WebkitAppearance: "none",
  },
  "&::-webkit-inner-spin-button": {
    display: "none",
    appearance: "none",
    WebkitAppearance: "none",
  },
});

interface Props extends Omit<FormTextFieldProps, "isRequired"> {
  isRequired?: boolean;
}

export function FormDateField({
  label,
  className,
  subLabel,
  disabled,
  ...props
}: Props) {
  const {
    register,
    formState: { errors, defaultValues },
  } = useFormContext();

  const { ref: rhfRef, ...restOfRegister } = register(props.name);

  const { defaultValue: nonRHFDefaultValue } = props;

  const withError = Boolean(errors[props.name]);
  const [touched, setTouched] = useState<boolean>(
    !!nonRHFDefaultValue || !!defaultValues?.[props.name]
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const styles = inputRecipe();

  return (
    <Box
      position="relative"
      w="full"
      pointerEvents={disabled ? "none" : "auto"}
    >
      <FormLabel htmlFor={props.name} mb={subLabel ? "1" : "3"}>
        {label}
      </FormLabel>
      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}

      <Box position="relative" w="full" overflow="hidden" mb="s">
        <FieldSymbol
          placement="left"
          onClick={() => {
            setTouched(true);
            if (inputRef.current) {
              inputRef.current.showPicker();
            }
          }}
        >
          <CalendarDaysIcon
            width="1rem"
            color={token("colors.grey.gunsmoke")}
          />
        </FieldSymbol>

        <styled.input
          className={cx(styles, className, resetStyles)}
          ref={(e) => {
            rhfRef(e);
            inputRef.current = e;
          }}
          onFocus={() => setTouched(true)}
          type="date"
          overflow="hidden"
          width="full"
          color={touched ? "#FFFFFF" : "#838383"}
          aria-errormessage={`${props.name}-error`}
          textIndent="1.5rem"
          style={{ height: "2.5rem" }}
          id={props.name}
          {...props}
          {...restOfRegister}
          {...(withError ? { "aria-invalid": "true" } : null)}
        />
      </Box>
      {withError ? (
        <FormError id={`${name}-error`}>
          {props.errorMessage || getFormFieldError(errors[props.name])}
        </FormError>
      ) : null}
    </Box>
  );
}
