/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * An event for a deal represents something external that has happened,
this will include things like deal closure, cancellation or funds wired.

Values:
- deal_all_funds_in: triggered by ops to indicate that all the funds anticipated
  for the deal have been received from investors.
- deal_funds_wired: triggered by ops to indicate that funds have been wired to
  the investee company from Odin's segregated account, but the receipt of said
  funds has not yet been acknowledged by the company.
- deal_closing_finalise_investments: triggered by ops to indicate that
  funds have been received, and to confirm that the calculated investment
  amounts and fees are correct.
- deal_close: finish the deal close process which will validate
  everything, send various emails and set the `closed_at` field.
- deal_cancel: mark the deal as cancelled and send an email to the SL.

 */
export type DealsEventsType =
  (typeof DealsEventsType)[keyof typeof DealsEventsType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealsEventsType = {
  deal_all_funds_in: "deal_all_funds_in",
  deal_funds_wired: "deal_funds_wired",
  deal_closing_finalise_investments: "deal_closing_finalise_investments",
  deal_close: "deal_close",
  deal_cancel: "deal_cancel",
} as const;
