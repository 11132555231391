/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The underlying investment vehicle type.
 */
export type InvestmentType =
  (typeof InvestmentType)[keyof typeof InvestmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestmentType = {
  equity: "equity",
  safe: "safe",
  saft: "saft",
  convertible: "convertible",
  seedfast: "seedfast",
  fund_investment: "fund_investment",
  secondary: "secondary",
} as const;
