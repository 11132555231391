import { Box } from "@@panda/jsx";
import { HTMLStyledProps } from "@@panda/types";

import { useAlertContext } from "../context";
import { alertStyles } from "../utils";

export function AlertDescription({
  children,
  ...rest
}: HTMLStyledProps<"div">) {
  const { variant } = useAlertContext();
  const styles = alertStyles({ variant });

  return (
    <Box className={styles.desc} {...rest}>
      {children}
    </Box>
  );
}
