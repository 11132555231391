import { Stack, VStack, styled } from "@@panda/jsx";
import { Card } from "components/display/Card";
import { Typography } from "components/display/Typography/Typography";
import { Button } from "components/main/Button";
import { UnauthorizedLayout } from "components/site/Layout/UnauthorizedLayout/UnauthorizedLayout";
import { CategoryTypes } from "screens/onboarding/steps/CategorisationStep/utils";

import { useCategoryList } from "./useCategoryList";

export function CategoryList() {
  const { onActiveCategory, onNoCategory, isLgScreenUp } = useCategoryList();

  return (
    <UnauthorizedLayout.Content style={{ maxWidth: "60rem" }}>
      <VStack mb="8">
        <Typography as="h2" variant="heading.2" textAlign="center">
          Select a category
        </Typography>

        <Typography
          as="p"
          variant="body"
          color="grey.gunsmoke"
          textAlign="center"
        >
          Pick the investor category that best applies to you.
        </Typography>
      </VStack>

      <VStack gap="l" mb="l">
        <Stack
          flexDirection={isLgScreenUp ? "row" : "column"}
          width="full"
          gap="l"
          alignItems={isLgScreenUp ? "stretch" : "flex-start"}
        >
          <Card
            width="full"
            textAlign="left"
            py="l"
            px="m"
            data-testid="self-certified-card"
            withBorder
          >
            <VStack alignItems="flex-start" gap="s">
              <Typography variant="heading.3" as="h3" lineHeight="tight">
                Self-certified sophisticated
              </Typography>

              <Typography
                color="grey.gunsmoke"
                as="p"
                variant="body"
                lineHeight="tight"
              >
                In the last two years, you’ve done{" "}
                <Typography as="span" variant="body" fontWeight="700">
                  ONE
                </Typography>{" "}
                of the following:
              </Typography>

              <styled.ul
                listStyle="inside"
                display="flex"
                flexDir="column"
                gap="l"
                py="m"
              >
                <li>Made two or more investments in an unlisted company.</li>

                <li>
                  Been a member of a network or syndicate of business angels for
                  more than 6 months, and are still a member;
                </li>

                <li>
                  Been the director of a company with an annual turnover of at
                  least £1 million;
                </li>

                <li>
                  Worked in a professional capacity in the private equity
                  sector, or in the provision of finance for small and medium
                  enterprises.
                </li>
              </styled.ul>

              <Button
                onClick={() => onActiveCategory(CategoryTypes.self_certified)}
                w="full"
              >
                This sounds like me
              </Button>
            </VStack>
          </Card>
          <Card
            width="full"
            textAlign="left"
            py="l"
            px="m"
            data-testid="high-net-worth-card"
            withBorder
          >
            <VStack alignItems="stretch" h="full" gap="s">
              <Typography variant="heading.3" as="h3" lineHeight="tight">
                High-net worth
              </Typography>

              <Typography
                color="grey.gunsmoke"
                as="p"
                variant="body"
                lineHeight="tight"
              >
                <Typography as="span" variant="body" fontWeight="700">
                  ONE
                </Typography>{" "}
                of the following statements must be true:
              </Typography>

              <styled.ul
                listStyle="inside"
                display="flex"
                flexDir="column"
                gap="l"
                py="m"
              >
                <li>
                  You had an income of more than £100,000 in the last financial
                  year;
                </li>

                <li>
                  You have had net assets of £250,000 or more (excluding your
                  primary residence and pension entitlements), in the last
                  financial year.
                </li>
              </styled.ul>

              <VStack gap={0} h="full" justifyContent="flex-end">
                <Button
                  onClick={() => onActiveCategory(CategoryTypes.high_net_worth)}
                  w="full"
                  justifySelf="flex-end"
                >
                  This sounds like me
                </Button>
              </VStack>
            </VStack>
          </Card>
        </Stack>
        <Button variant="link" as="button" onClick={onNoCategory}>
          Neither of these apply to me
        </Button>
      </VStack>
    </UnauthorizedLayout.Content>
  );
}
