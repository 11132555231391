import { RecipeVariantProps } from "@@panda/css";
import { Tooltip as ArkTooltip, Portal, TooltipRootProps } from "@ark-ui/react";
import { ComponentProps } from "react";

import { tooltipRecipe } from "./styles";

type VariantProps = RecipeVariantProps<typeof tooltipRecipe>;

type Props = TooltipRootProps &
  VariantProps & {
    label: string | React.ReactElement;
    children: string | React.ReactElement;
    triggerStyle?: string;
    triggerProps?: ComponentProps<"div">;
  };

export function Tooltip({
  label,
  children,
  variant,
  size,
  triggerStyle = "",
  triggerProps,
  ...rest
}: Props) {
  return (
    <ArkTooltip.Root
      openDelay={100}
      closeDelay={0}
      interactive
      lazyMount
      unmountOnExit
      {...rest}
    >
      <ArkTooltip.Trigger asChild>
        <div className={triggerStyle} {...triggerProps}>
          {children}
        </div>
      </ArkTooltip.Trigger>

      <Portal>
        <ArkTooltip.Positioner>
          <ArkTooltip.Content className={tooltipRecipe({ variant, size })}>
            {label}
          </ArkTooltip.Content>
        </ArkTooltip.Positioner>
      </Portal>
    </ArkTooltip.Root>
  );
}
