import { useContext } from "react";
import { ClientCategorizationContext } from "screens/onboarding/steps/CategorisationStep/context";

export function useAssessmentFailure() {
  const { setActiveStep } = useContext(ClientCategorizationContext);

  function onStartAgain() {
    setActiveStep("list");
  }

  return {
    onStartAgain,
  };
}
