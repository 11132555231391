import { urlNormalize } from "url-normalize";
import { z } from "zod";

export const createNameAndLabels = <
  TSchema extends z.ZodTypeAny,
  const TObj extends Record<keyof z.infer<TSchema>, string>,
>(
  _zodSchema: TSchema,
  obj: TObj
) => ({
  getNameAndLabel: (formField: keyof z.infer<TSchema>) => ({
    name: formField,
    label: obj[formField],
  }),
});

interface GetUrlFieldFormSchemaArgs {
  required: boolean;
  message?: string;
}

export function getUrlFieldFormSchema({
  required,
  message,
}: GetUrlFieldFormSchemaArgs) {
  const schema = z
    .string()
    .refine((val: string) => Boolean(urlNormalize(val)), {
      message: message ?? "Please insert a valid URL",
    })
    .transform(
      (val: string) =>
        urlNormalize(val, { forceProtocol: "https", www: true }) ?? ""
    );

  return required ? schema : schema.or(z.literal(""));
}
