import { useState } from "react";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

type Args = {
  controlled:
    | {
        isOpen: boolean;
        set: (open: boolean) => Promise<void> | void;
      }
    | undefined;
};

export function useDialogInternal({ controlled }: Args) {
  const [isOpen, setIsOpen] = useState(false);

  const { isLgScreenUp } = useBreakpoint("lgUp");
  const [isLgScreenWhenFirstOpened, setIsLgScreenWhenFirstOpened] =
    useState(isLgScreenUp);
  // if first opened as modal, or drawer - we need to keep it this way
  // because, given the current implementation, we can't switch between modal and drawer without a full remount

  const handleOpen = async () => {
    setIsLgScreenWhenFirstOpened(isLgScreenUp);

    if (controlled) {
      await controlled.set(true);
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = async () => {
    if (controlled) {
      await controlled.set(false);
    } else {
      setIsOpen(false);
    }
  };

  return {
    isOpen: controlled ? controlled.isOpen : isOpen,
    handleOpen,
    handleClose,
    isLgScreenUp: isLgScreenWhenFirstOpened,
  };
}
