import { useRouter } from "next/router";
import { computeNeedsOnboarding } from "screens/onboarding/utils";
import { useAuth } from "services/auth/AuthProvider";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

export function useLoggedInView() {
  const router = useRouter();
  const { account } = useAuth();

  const isOnboarding = computeNeedsOnboarding(account);

  const isDeals = router.pathname === "/" || router.pathname === "/deals";

  const isCommunityPage =
    router.pathname === "/syndicates" ||
    router.pathname === "/syndicates/create";

  const isTaxAndDocumentsPage = router.pathname === "/tax-and-documents";

  const { isLgScreenUp } = useBreakpoint("lgUp");

  return {
    isLgScreenUp,
    isOnboarding,
    isCommunityPage,
    isTaxAndDocumentsPage,
    isDeals,
  };
}
