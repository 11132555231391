import { createContext, useContext } from "react";

import { menuRecipe } from "./styles";

export interface MenuContextShape {
  styles: ReturnType<typeof menuRecipe>;
}

export const MenuContext = createContext<MenuContextShape>({
  styles: {},
});

export function useMenuContext() {
  const result = useContext(MenuContext);
  return result;
}
