import { RecipeVariantProps, cx } from "@@panda/css";
import {
  Content,
  DropdownMenuContentProps,
  DropdownMenuSeparatorProps,
  DropdownMenuTriggerProps,
  Portal,
  Root,
  Separator,
  Trigger,
} from "@radix-ui/react-dropdown-menu";
import { PropsWithChildren, forwardRef } from "react";

import { MenuItem } from "./components/MenuItem";
import { MenuContext, useMenuContext } from "./context";
import { menuRecipe } from "./styles";

const MenuTrigger = forwardRef<HTMLButtonElement, DropdownMenuTriggerProps>(
  ({ className, ...rest }, ref) => {
    const { styles } = useMenuContext();
    return (
      <Trigger ref={ref} className={cx(styles.trigger, className)} {...rest} />
    );
  }
);

MenuTrigger.displayName = "MenuTrigger";

const MenuContent = forwardRef<HTMLDivElement, DropdownMenuContentProps>(
  ({ className, ...rest }, ref) => {
    const { styles } = useMenuContext();
    return (
      <Content
        ref={ref}
        arrowPadding={16}
        align="end"
        className={cx(styles.content, className)}
        {...rest}
      />
    );
  }
);

MenuContent.displayName = "MenuContent";

const MenuSeparator = forwardRef<HTMLDivElement, DropdownMenuSeparatorProps>(
  ({ className, ...rest }, ref) => {
    const { styles } = useMenuContext();
    return (
      <Separator
        ref={ref}
        className={cx(styles.separator, className)}
        {...rest}
      />
    );
  }
);

MenuSeparator.displayName = "MenuSeparator";

Menu.Trigger = MenuTrigger;
Menu.Content = MenuContent;
Menu.Separator = MenuSeparator;
Menu.Item = MenuItem;
Menu.Portal = Portal;

type VariantProps = RecipeVariantProps<typeof menuRecipe>;

type MenuProps = PropsWithChildren &
  VariantProps & {
    onOpenChange?: (oepn: boolean) => void;
  };

export function Menu({ children, onOpenChange, variant }: MenuProps) {
  const styles = menuRecipe({ variant });

  return (
    <Root {...(onOpenChange ? { onOpenChange } : null)}>
      <MenuContext.Provider value={{ styles }}>{children}</MenuContext.Provider>
    </Root>
  );
}
