import {
  getAccountsGetSelfQueryKey,
  useAccountsResetPassword,
} from "@internal/rest/generated/queries/accounts";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useState } from "react";
import { handleResourceEffect } from "utility/async";

import { OnboardingStepPaths } from "../../steps";
import { goToStep } from "../../utils";
import { FormValues } from "./utils";

export function useSetPasswordStep() {
  const router = useRouter();
  const [isSubmitting, setSubmitting] = useState(false);
  const { mutateAsync: resetPassword } = useAccountsResetPassword();
  const queryClient = useQueryClient();

  async function onSubmit(data: FormValues) {
    await handleResourceEffect({
      async action() {
        setSubmitting(true);

        await resetPassword({
          data: { password: data.password },
        });

        await queryClient.invalidateQueries({
          queryKey: getAccountsGetSelfQueryKey(),
        });

        await goToStep(router, OnboardingStepPaths.InvestorDetails);
      },
      error: {
        title: "Reset Password Error",
      },
      cleanup() {
        setSubmitting(false);
      },
    });
  }

  return {
    onSubmit,
    isSubmitting,
  };
}
