/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type InvestmentRound =
  (typeof InvestmentRound)[keyof typeof InvestmentRound];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InvestmentRound = {
  pre_seed: "pre_seed",
  seed: "seed",
  bridge_to_series_a: "bridge_to_series_a",
  series_a: "series_a",
  bridge_to_series_b: "bridge_to_series_b",
  series_b: "series_b",
  bridge_to_series_c: "bridge_to_series_c",
  series_c: "series_c",
  bridge_to_series_d: "bridge_to_series_d",
  series_d: "series_d",
  secondary: "secondary",
} as const;
