import { GetFormLabels } from "@internal/utils/types";
import { createNameAndLabels } from "utility/forms";
import { z } from "zod";

export const errorMsg = {
  password: "Password must contain at least 8 characters",
  passwordMismatch: "Passwords must match",
};

export const schema = z
  .object({
    password: z.string().min(8, { message: errorMsg.password }),
    repeatPassword: z.string(),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: errorMsg.passwordMismatch,
    path: ["repeatPassword"],
  });

export const { getNameAndLabel } = createNameAndLabels(schema, {
  password: "Password*",
  repeatPassword: "Re-enter your password*",
});

export type FormValues = z.infer<typeof schema>;
export type FormLabels = GetFormLabels<typeof getNameAndLabel>;
