import { z } from "zod";

import { APIError } from "../generated/schemas";

export const genericError = "Unexpected error occurred";
export const genericMessage = "Please contact us at hello@joinodin.com";

export const APIErrorSchema = z.object({
  error: z.string(),
  message: z.string().optional(),
  correlation_id: z.string().optional(),
  metadata: z.record(z.string(), z.string()).optional(),
  path: z.string().optional(),
});

export type APIErrorWithStatus = APIError & {
  status?: number | undefined;
  path?: string;
};

/**
 * Derives a consistent error object from exception or response input.
 *
 * Use this when you have an unknown error type and you want to get an object of
 * type `APIError` for use with <ErrorBanner /> or other error handling APIs.
 *
 * Most of the time you should use the `useErrorHandler` hook as this is a more
 * low level API.
 *
 * @param e Exception or error response object
 * @returns An APIError object either parsed from `e` or built from toString
 */
export function deriveError(
  e: unknown,
  status?: number,
  path?: string
): APIErrorWithStatus {
  const parsed = APIErrorSchema.safeParse(e);

  if (parsed.success) {
    return {
      error: parsed.data.error,
      message: parsed.data.message ?? "",
      correlation_id: parsed.data.correlation_id ?? "",
      status,
      ...(path ? { path } : null),
    };
  }

  if (e instanceof Error) {
    const { message } = e;
    if (message) {
      return { message, error: genericError, status };
    }

    return { message: genericMessage, error: e.toString(), status };
  }

  if (e === undefined) {
    return {
      error: genericError,
      message: genericMessage,
      status,
    };
  }

  return {
    error: JSON.stringify(e),
    message:
      "deriveError was passed a value that was neither Error or APIError.",
    status,
  };
}

/**
 * Formats an API error into a nice human readable and user friendly message.
 */
export function formatDescription(e: APIError) {
  if (e.message) {
    // Best case scenario, we give the user a well-written error message.
    return `${e.message} (Error: ${e.error})`;
  }

  if (e.error) {
    // Bad: no user-friendly message avaiable, just show the technical error.
    return `Error: ${e.error}`;
  }

  // Literally UX and support hell, avoid this.
  return genericError;
}

/**
 * This error is thrown when the API responds with a "429 Too Many Requests". It
 * implements the `APIError` type and fills all the fields with some information
 * about the rate limit so instances of this class can go into `<ErrorBanner />`
 */
export class RateLimitError implements APIError {
  public message: string;
  public error: string;
  public correlation_id: string;

  constructor(r: Response, path: string) {
    const limit = r.headers.get("x-ratelimit-limit");
    const correlationID = r.headers.get("X-Correlation-ID");

    const message = limit
      ? `You have exceeded the request rate limit (${limit} requests per minute) for a particular resource (${path}) required for this page.`
      : `You have exceeded the request rate limit for a particular resource (${path}) required for this page.`;

    this.error = "rate limit exceeded";
    this.message = `${message}`;
    this.correlation_id = correlationID ?? "";
  }
}
