import { Account } from "@internal/rest/generated/schemas";

import { Local } from "./local";
import { Mixpanel } from "./mixpanel";

export type MixpanelEvent = {
  name: "Deal admin action" | "Deal page action";
  type: string;
};

interface AnalyticsService {
  register(a: Account): void;
  track: (e: MixpanelEvent) => void;
  reset(): void;
}

function init(): AnalyticsService {
  if (typeof window === "undefined") {
    return new Local();
  }

  if (process.env["VERCEL_ENV"] !== "production") {
    return new Local();
  }

  return new Mixpanel();
}

const analytics = init();

export { analytics };
