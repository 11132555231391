/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type LegalEntityRelationshipFilterParameter =
  (typeof LegalEntityRelationshipFilterParameter)[keyof typeof LegalEntityRelationshipFilterParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalEntityRelationshipFilterParameter = {
  all: "all",
  controlled: "controlled",
  referenced: "referenced",
} as const;
