import { styled } from "@@panda/jsx";
import { PropsWithChildren } from "react";

export function StateIndicator({ children, ...rest }: PropsWithChildren) {
  return (
    <styled.div
      position="absolute"
      top="50%"
      transform="translateY(-50%)"
      right="0"
      w="35px"
      h="30px"
      p="0"
      bg="grey.woodsmoke"
      display="flex"
      mx="0"
      py="2px"
      alignItems="center"
      justifyContent="center"
      {...rest}
    >
      {children}
    </styled.div>
  );
}
