import { styled } from "@@panda/jsx";
import { HTMLStyledProps } from "@@panda/types";

export function Divider(props: HTMLStyledProps<"hr">) {
  return (
    <styled.hr
      borderStyle="solid"
      borderWidth="0.5px 0px 0.5px 0px"
      {...props}
      style={{
        borderColor: "#f9fafb17",
      }}
    />
  );
}
