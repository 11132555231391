import { useRouter } from "next/router";

// ts function overloads

export function useQueryState(
  paramKey: string
): readonly [value: string | null, (newValue: string) => Promise<void>];

export function useQueryState(
  paramKey: string,
  options: { fallback: string }
): readonly [value: string, (newValue: string) => Promise<void>];

export function useQueryState<const TValues extends readonly string[]>(
  paramKey: string,
  options: { values: TValues }
): readonly [
  value: TValues[number] | null,
  (newValue: TValues[number]) => Promise<void>,
];

export function useQueryState<const TValues extends readonly string[]>(
  paramKey: string,
  options: { values: TValues; fallback: TValues[number] }
): readonly [
  value: TValues[number],
  (newValue: TValues[number]) => Promise<void>,
];

export function useQueryState<const TValues extends readonly string[]>(
  paramKey: string,
  options?: { values?: TValues; fallback?: TValues[number] }
) {
  const router = useRouter();
  const query = router.query;
  const paramValue = query[paramKey];

  const getValue = () => {
    if (!options) return paramValue as string | null;

    if (!options.fallback)
      return options?.values?.includes(paramValue as TValues[number])
        ? (paramValue as TValues[number])
        : (paramValue as string);

    if (options?.values && !options.fallback) {
      return options.values.includes(paramValue as TValues[number])
        ? (paramValue as TValues[number])
        : (paramValue as string);
    }

    if (!options?.values && options?.fallback) {
      return (paramValue ?? options.fallback) as string;
    }

    return options?.values?.includes(paramValue as TValues[number])
      ? (paramValue as TValues[number])
      : (options?.fallback as TValues[number]);
  };

  const setParam = async (newValue: TValues[number]) => {
    await router.push(
      { query: { ...router.query, [paramKey]: newValue } },
      undefined,
      { scroll: false }
    );
  };

  return [getValue(), setParam] as const;
}
