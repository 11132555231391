/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Indicates whether or not the syndicate leads can be changed.

 */
export type SyndicateLeadsMutability =
  (typeof SyndicateLeadsMutability)[keyof typeof SyndicateLeadsMutability];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SyndicateLeadsMutability = {
  mutable: "mutable",
  immutable_has_in_review_drafts: "immutable_has_in_review_drafts",
  immutable_has_published_deals: "immutable_has_published_deals",
} as const;
