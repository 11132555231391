import { css } from "@@panda/css";
import { Box, HTMLStyledProps, styled } from "@@panda/jsx";
import { inputStyles } from "components/forms/Form/FormTextField/styles";
import React from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";

interface Props extends HTMLStyledProps<"textarea"> {
  name: string;
  label?: string;
  subLabel?: string | React.ReactNode;
  fieldOpts?: RegisterOptions;
  errorMessage?: string;
}

export function FormTextArea({
  name,
  label,
  subLabel,
  fieldOpts = {},
  errorMessage,
  ...rest
}: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const withError = Boolean(errors[name]);

  return (
    <Box w="full" position="relative">
      <FormLabel htmlFor={name} withSubLabel={Boolean(subLabel)}>
        {label}
      </FormLabel>
      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}
      <styled.textarea
        id={name}
        minH="7rem"
        aria-errormessage={`${name}-error`}
        className={css(inputStyles)}
        {...(withError ? { "aria-invalid": "true" } : null)}
        {...register(name, fieldOpts)}
        {...rest}
      />
      {withError ? (
        <FormError id={`${name}-error`}>
          {errorMessage || getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
