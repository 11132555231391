import { css, cx } from "@@panda/css";
import { Styled } from "components/styled";
import NextImg from "next/image";
import { HTMLAttributes } from "react";
import { environment } from "services/environment";
import { convertRemToPixels } from "utility/images";

import { useThumbnail } from "./useThumbnail";
import { PlaceholderVariant, getPlaceholder } from "./utils";

const env = environment.getEnvironment();
const isProduction = env.API_ENV === "production";

interface Props extends Omit<HTMLAttributes<HTMLImageElement>, "src"> {
  src?: string;
  circle?: boolean;
  placeholder?: PlaceholderVariant;
  alt: string;
  isActive?: boolean;
  testId?: string;
  width?: string;
  height?: string;
}

export function Thumbnail({
  src = "",
  circle = false,
  isActive = false,
  placeholder = "profile",
  testId = "",
  alt = "",
  width,
  height,
  className: outerClassName,
  ...rest
}: Props) {
  const { loadError, handleFailedLoad, isTestAccount } = useThumbnail();

  const Tag = isProduction && !isTestAccount ? NextImg : Styled.img;

  const styles = cx(
    css({
      overflow: "hidden",
      borderStyle: "solid",
      borderRadius: circle ? "full" : "lg",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      bgColor: placeholder === "deal" && loadError ? "white" : "grey.bunker",
      borderWidth: "1px",
      borderColor: "grey.minecraft",
      _expanded: {
        borderWidth: "2px",
        borderColor: "brand.krilin!",
      },
      ...rest,
    }),
    outerClassName
  );

  if (loadError || !src) {
    return (
      <div
        className={styles}
        data-src={src}
        data-active={isActive}
        data-testid={testId}
        style={{ width, height }}
      >
        {getPlaceholder(placeholder)}
      </div>
    );
  }

  return (
    <Tag
      data-active={isActive}
      data-testid={testId}
      src={src}
      width={convertRemToPixels(width || "")}
      height={convertRemToPixels(height || "")}
      alt={alt}
      data-src={src}
      aria-expanded={isActive}
      data-production={isProduction}
      className={styles}
      style={{
        backgroundSize: "115%",
        width,
        height,
      }}
      onError={() => {
        handleFailedLoad();
      }}
    />
  );
}
