import { PropsWithChildren } from "react";
import { Toaster } from "sonner";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

export function ToastProvider({ children }: PropsWithChildren) {
  const { isLgScreenUp } = useBreakpoint("lgUp");

  return (
    <>
      {children}
      <Toaster
        position={isLgScreenUp ? "bottom-center" : "top-center"}
        theme="dark"
        richColors
        toastOptions={{ className: "sonner-toast" }}
        closeButton
      />
    </>
  );
}
