import { Typography } from "components/display/Typography/Typography";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLSpanElement> {
  placement: "left" | "right";
}

export function FieldSymbol({ placement, children, onClick, ...rest }: Props) {
  const placementProps =
    placement === "left"
      ? { left: "13px", borderRightWidth: "1px", pr: "m", pl: "1px" }
      : { right: "13px", borderLeftWidth: "1px", pl: "m", pr: "1px" };

  return (
    <Typography
      variant="body"
      position="absolute"
      color="grey.gunsmoke"
      as="span"
      borderColor="grey.dune!"
      borderStyle="solid"
      height="38px"
      top="1px"
      display="flex"
      alignItems="center"
      data-testid="amount-field-symbol"
      onClick={onClick}
      cursor={onClick ? "pointer" : "auto"}
      {...placementProps}
      {...rest}
    >
      {children}
    </Typography>
  );
}
