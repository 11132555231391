import { useEffect } from "react";

interface Args {
  initialValue: string;
  fieldName: string;
  setValue: (name: string, value: string) => void;
}

export function useFormAmountField({
  setValue,
  fieldName,
  initialValue,
}: Args) {
  useEffect(() => {
    if (initialValue) {
      setValue(fieldName, initialValue);
    }
  }, []);
}
