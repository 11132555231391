import { Form } from "components/forms/Form/Form";
import { z } from "zod";

import { CategoryTypes } from "../../utils";
import { CategoryDetail } from "../CategoryDetail/CategoryDetail";
import { FormFields } from "../CategoryDetail/utils";

export function HighNetWorth() {
  return (
    <CategoryDetail
      type={CategoryTypes.high_net_worth}
      title="High Net Worth"
      desc="Please specify which statement applies to you."
      statements={[
        {
          text: "In the last financial year, I have had an annual income of £100,000 or more.",
          subText: (
            <>
              Income does <strong> not </strong> include any one-off pension
              withdrawals.
            </>
          ),
          question:
            "Please specify your income (as defined above) to the nearest £10,000 in the last financial year.",
          placeholder: "Your income",
          field: (
            <Form.AmountField
              leftSymbol="£"
              name={FormFields.Answer}
              label="Please specify your income (as defined above) to the nearest £10,000 in the last financial year."
            />
          ),
          schema: z
            .object({
              [FormFields.Answer]: z
                .string()
                .min(1, { message: "Please provide an answer" }),
            })
            .refine(
              (values) => {
                if (+values[FormFields.Answer] < 100000) return false;
                return true;
              },
              {
                path: [FormFields.Answer],
                message: "Please insert an amount greater or equal to £100,000",
              }
            ),
        },
        {
          text: "In the last financial year, I have had net assets of £250,000 or more.",
          subText:
            "Net assets do not include: your home (primary residence), any loan secured on it or any equity released from it, your pension (or any pension withdrawals) or any rights under qualifying contracts of insurance. Net assets are total assets minus any debts you owe.",
          question:
            "Please specify your net assets (as defined above) to the nearest £10,000 in the last financial year.",
          placeholder: "Your income",
          field: (
            <Form.AmountField
              leftSymbol="£"
              name={FormFields.Answer}
              label="Please specify your net assets (as defined above) to the nearest £10,000 in the last financial year."
            />
          ),
          schema: z
            .object({
              [FormFields.Answer]: z
                .string()
                .min(1, { message: "Please provide an answer" }),
            })
            .refine(
              (values) => {
                if (+values[FormFields.Answer] < 250000) return false;
                return true;
              },
              {
                path: [FormFields.Answer],
                message: "Please insert an amount greater or equal to £250,000",
              }
            ),
        },
      ]}
    />
  );
}
