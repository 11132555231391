import { createContext } from "react";

import { ActiveStep } from "./useCategorisationStep";
import { CategoryType, Step } from "./utils";

interface ContextShape {
  setActiveStep: (step: Step, category?: CategoryType) => void;
  activeStep: ActiveStep;
}

export const ClientCategorizationContext = createContext<ContextShape>({
  setActiveStep: () => undefined,
  activeStep: "list",
});
