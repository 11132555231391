import { Center, VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Button } from "components/main/Button";
import { ReactElement } from "react";

import { StepWrap } from "./StepWrap";

interface Props {
  title: string;
  desc: ReactElement;
  icon: ReactElement;
  onContinue: () => void;
}

export function StepIntro({ title, desc, icon, onContinue }: Props) {
  return (
    <StepWrap>
      <VStack gap="l">
        <Center bgColor="grey.minecraft" borderRadius="sm" h="10.5rem" w="full">
          <Center
            borderRadius="full"
            w="3.48438rem"
            h="3.48438rem"
            boxShadow="0px 0px 14px 0px #D7905E"
            bg="linear-gradient(180deg, #E65100 12.82%, #BF360C 132.12%)"
          >
            {icon}
          </Center>
        </Center>

        <Typography variant="heading.2" textAlign="center">
          {title}
        </Typography>

        <Typography variant="body" color="grey.gunsmoke" textAlign="center">
          {desc}
        </Typography>

        <Button onClick={onContinue} w="full">
          Continue
        </Button>
      </VStack>
    </StepWrap>
  );
}
