import { Center } from "@@panda/jsx";
import { OdinLogo } from "components/main/OdinLogo/OdinLogo";

import { NAV_BTN_SIZE } from "./LoggedInView/LoggedInView";

export function LoggedOutView() {
  return (
    <Center py="0.75rem">
      <OdinLogo w={NAV_BTN_SIZE} h={NAV_BTN_SIZE} />
    </Center>
  );
}
