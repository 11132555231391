import { z } from "zod";

import { ExtendedURLSearchParamsArg, fetchAPI } from "./api";
import { APIErrorWithStatus } from "./errors";
import { isAborted, withAbortControllerSupport } from "./utils";

const isTestEnv = process.env.NODE_ENV === "test";
const REQUEST_TIMEOUT = 20000;

type Options = {
  url: string;
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  headers?: Record<string, string>;
  params?: ExtendedURLSearchParamsArg;
  data?: unknown;
  responseType?: string;
  signal?: AbortSignal | undefined;
};

export async function fetcher<T>({
  url,
  method,
  headers,
  params,
  data,
}: Options): Promise<T> {
  try {
    const withAbortController =
      typeof window !== "undefined"
        ? withAbortControllerSupport(window)
        : false;

    const canCancel = withAbortController && !isTestEnv;

    const response = await fetchAPI(url, {
      schema: z.any(),
      method: method.toUpperCase(),
      mode: "cors",
      credentials: "include",
      ...(params ? { query: params } : {}),
      ...(canCancel ? { signal: AbortSignal.timeout(REQUEST_TIMEOUT) } : null),
      ...(headers ? { headers } : {}),
      ...(data
        ? { body: data instanceof Blob ? data : JSON.stringify(data) }
        : {}),
    });

    return response;
  } catch (e) {
    const err = e as Error | APIErrorWithStatus;

    if (isAborted(err)) {
      // SafeCast: `isAborted` is checking the right type here...
      const finalError = err as Error;
      finalError.name = `Network Error: ${url} (${finalError.name})`;

      if (typeof window !== "undefined")
        window.alert(
          "Network issue: It looks like there's an issue with your network connection. Please check you're connected to the internet and try again."
        );
    }

    throw err;
  }
}
