type Props = {
  color?: string;
  label?: string;
  size?: string;
  strokeWidth?: string;
} & React.HTMLAttributes<SVGElement>;

export function Spinner(props: Props) {
  const {
    label = "Loading...",
    color = "#b18362",
    size = "1rem",
    strokeWidth = "10",
    ...rest
  } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      aria-label={label}
      {...rest}
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke={color}
        strokeWidth={strokeWidth}
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          repeatCount="indefinite"
          dur=".6s"
          values="0 50 50;360 50 50"
          keyTimes="0;1"
        ></animateTransform>
      </circle>
    </svg>
  );
}

Spinner.displayName = "Spinner";
