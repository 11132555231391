import { StatusCover } from "components/feedback/StatusCover/StatusCover";
import { Button } from "components/main/Button";
import { PropsWithChildren, useEffect, useState } from "react";

export function NetworkConnectionProvider({ children }: PropsWithChildren) {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnline(false);
    });

    window.addEventListener("online", () => {
      setOnline(true);
    });
  }, []);

  return isOnline ? (
    <>{children}</>
  ) : (
    <>
      <StatusCover
        title="You’re offline"
        tip="Try refreshing when you have an internet connection"
      >
        <Button variant="secondary" as="a" href="mailto:hello@joinodin.com">
          Something wrong? Contact support
        </Button>
      </StatusCover>
    </>
  );
}
