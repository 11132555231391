/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Each of the client categories is from the FCA guidelines.

## self_certified

A self-certified sophisticated investor is an individual who has completed and
signed, within the period of twelve months ending on the day on which the
communication is made, a statement in the terms set out in COBS 4 Annex
4R, and whose completion of the statement indicates that they meet the
relevant criteria to be categorised as such.

Read more here:

https://www.handbook.fca.org.uk/handbook/COBS/4/12.html#DES586

## certified_sophisticated

A certified sophisticated investor is an individual:

(1) who has a written certificate signed within the last 36 months by a firm confirming they have been assessed by that firm as sufficiently knowledgeable to understand the risks associated with engaging in investment activity in non-mass market investments; and 

(2) who has completed and signed, within the period of twelve months ending on the day on which the communication is made, a statement in the terms set out in COBS 4 Annex 3R, and whose completion of the statement indicates that they meet the relevant criteria to be categorised as such.

Read more here:

https://www.handbook.fca.org.uk/handbook/COBS/4/12.html#DES583

## high_net_worth

A certified high net worth investor is an individual who has completed and signed, within the period of twelve months ending on the day on which the communication is made, a statement in the terms set out in COBS 4 Annex 2R, and whose completion of the statement indicates that they meet the relevant criteria to be categorised as such.

Read more here:

https://www.handbook.fca.org.uk/handbook/COBS/4/12.html#DES582

## accredited

See the below links for information about this. Essentially, this is a
US-specific category used by the securities and exchange commission.

https://www.ecfr.gov/current/title-17/chapter-II/part-230/subject-group-ECFR6e651a4c86c0174/section-230.501

https://www.investopedia.com/terms/a/accreditedinvestor.asp

 */
export type ClientCategorisationCategory =
  (typeof ClientCategorisationCategory)[keyof typeof ClientCategorisationCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientCategorisationCategory = {
  self_certified: "self_certified",
  high_net_worth: "high_net_worth",
  certified_sophisticated: "certified_sophisticated",
  accredited: "accredited",
} as const;
