import { HStack } from "@@panda/jsx";
import { Button } from "components/main/Button";
import { Dialog } from "components/main/Dialog";

import { Editor } from "./components/Editor";
import { PreviewBox } from "./components/PreviewBox";
import { useAvatarEditor } from "./useAvatarEditor";

export interface RenderChildrenArgs {
  avatarUrl: string;
  withError: boolean;
  openModal: () => void;
}

export interface ImgArgs {
  img: Blob;
  url: string;
}

AvatarEditor.PreviewBox = PreviewBox;

interface Props {
  onSaveAvatar: (img: ImgArgs) => Promise<void>;
  withError?: boolean;
  avatarUrl?: string;
  weightLimit?: number;
  inline?: boolean;
  secondaryAction?: React.ReactNode;
  children?: (args: RenderChildrenArgs) => React.ReactNode;
}

export function AvatarEditor({
  onSaveAvatar,
  withError,
  avatarUrl,
  weightLimit,
  secondaryAction,
  children,
  inline = false,
}: Props) {
  const {
    isOpen,
    setOpen,
    size,
    rotation,
    selectedImage,
    savedAvatar,
    editorRef,
    isSavingAvatar,
    weightError,
    weightConstraint,
    handleSaveImage,
    handleUploadImage,
    handleChangeRotation,
    handleChangeSize,
  } = useAvatarEditor({
    onSaveAvatar,
    avatarUrl,
    weightLimit,
  });

  const ActionsFooter = (
    <HStack justifyContent="flex-start" pb="0.75rem" pt="l" gap="m">
      {secondaryAction || null}
      <Button
        onClick={handleSaveImage}
        isDisabled={!selectedImage}
        isLoading={isSavingAvatar}
        type="button"
      >
        Save
      </Button>
    </HStack>
  );

  const InlineEditor = (
    <Editor
      selectedImage={selectedImage}
      size={size}
      rotation={rotation}
      editorRef={editorRef}
      weightError={weightError}
      weightConstraint={weightConstraint}
      isSavingAvatar={isSavingAvatar}
      onSaveImage={handleSaveImage}
      onChangeRotation={handleChangeRotation}
      onChangeSize={handleChangeSize}
      onUploadImage={handleUploadImage}
    />
  );

  return inline ? (
    <>
      {InlineEditor}
      {ActionsFooter}
    </>
  ) : (
    <>
      <Dialog
        title="Upload a photo"
        controlled={{ isOpen, set: setOpen }}
        data-testid="avatar-editor-modal"
        styling={{
          width: "25rem",
        }}
      >
        {children?.({
          avatarUrl: savedAvatar || "",
          withError: withError || false,
          openModal: () => setOpen(true),
        })}
        <Dialog.Content>
          {InlineEditor}
          {ActionsFooter}
        </Dialog.Content>
      </Dialog>
    </>
  );
}
