import { CompanyPlaceholder } from "./placeholders/company";
import { DealPlaceholder } from "./placeholders/deal";

export type PlaceholderVariant = "profile" | "deal" | "company";

export function getPlaceholder(variant: PlaceholderVariant) {
  return variant === "deal" ? (
    <DealPlaceholder />
  ) : variant === "company" ? (
    <CompanyPlaceholder />
  ) : null;
}

export function isTestingAccount(accountEmail: string) {
  return (
    accountEmail.includes("@test.odin") ||
    accountEmail.includes("@joinodin.com")
  );
}
