/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */
import { useQuery } from "@tanstack/react-query";
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";

import { fetcher } from "../../client/client";
import type { GetVersion200 } from "../schemas";

type AwaitedInput<T> = PromiseLike<T> | T;

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;

/**
 * Get the openapi spec as JSON.
 */
export const getSpec = (signal?: AbortSignal) => {
  return fetcher<string>({ url: `/openapi.json`, method: "GET", signal });
};

export const getGetSpecQueryKey = () => {
  return [`/openapi.json`] as const;
};

export const getGetSpecQueryOptions = <
  TData = Awaited<ReturnType<typeof getSpec>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSpecQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpec>>> = ({
    signal,
  }) => getSpec(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSpec>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSpecQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSpec>>
>;
export type GetSpecQueryError = unknown;

export const useGetSpec = <
  TData = Awaited<ReturnType<typeof getSpec>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSpec>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetSpecQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * Get the platform version.
 */
export const getVersion = (signal?: AbortSignal) => {
  return fetcher<GetVersion200>({ url: `/version`, method: "GET", signal });
};

export const getGetVersionQueryKey = () => {
  return [`/version`] as const;
};

export const getGetVersionQueryOptions = <
  TData = Awaited<ReturnType<typeof getVersion>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getVersion>>, TError, TData>
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetVersionQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersion>>> = ({
    signal,
  }) => getVersion(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getVersion>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetVersionQueryResult = NonNullable<
  Awaited<ReturnType<typeof getVersion>>
>;
export type GetVersionQueryError = unknown;

export const useGetVersion = <
  TData = Awaited<ReturnType<typeof getVersion>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getVersion>>, TError, TData>
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetVersionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
