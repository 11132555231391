/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * A subset of the internal deal statuses that are related to closing

 */
export type ClosingDealStatus =
  (typeof ClosingDealStatus)[keyof typeof ClosingDealStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClosingDealStatus = {
  all_funds_in: "all_funds_in",
  awaiting_legals: "awaiting_legals",
  reviewing_legals: "reviewing_legals",
  funds_wired: "funds_wired",
} as const;
