import trimEnd from "lodash/trimEnd";

// VERCEL_URL is not a valid URL 🙄
const vercelURL = process.env["NEXT_PUBLIC_VERCEL_URL"]
  ? `https://${process.env["NEXT_PUBLIC_VERCEL_URL"]}`
  : undefined;

const clean = (addr: string) => trimEnd(addr, "/").trim();

export const API_ADDRESS = clean(
  process.env["NEXT_PUBLIC_API_ADDRESS"] ?? "https://asgard-dev.fly.dev"
);

export const WEB_ADDRESS = clean(
  process.env["NEXT_PUBLIC_WEB_ADDRESS"] ?? vercelURL ?? "http://localhost:3000"
);

export const VERSION = process.env["NEXT_PUBLIC_VERSION"] ?? "unknown";
