/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type PricePlanBasePlusVariableVariant =
  (typeof PricePlanBasePlusVariableVariant)[keyof typeof PricePlanBasePlusVariableVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PricePlanBasePlusVariableVariant = {
  base_plus_variable: "base_plus_variable",
} as const;
