import { styled } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { Typography } from "components/display/Typography/Typography";
import { buttonRecipe } from "components/main/Button/styles";

import { useUploadFile } from "./useUploadFile";

interface Props {
  label: string;
  accept: string;
  onChange: (img: File) => void;
  name: string;
  id: string;
}

export function UploadFile({ label, accept, onChange }: Props) {
  const { inputRef } = useUploadFile();

  return (
    <styled.label
      className={buttonRecipe({ variant: "link" })}
      style={{
        cursor: "pointer",
        alignItems: "center",
        justifyContent: "flex-start",
        padding: "0",
      }}
    >
      <input
        type="file"
        ref={inputRef}
        onChange={(e) => {
          const file = e.target.files && e.target.files[0];
          if (file) onChange(file);
        }}
        style={{ width: 0, height: 0, opacity: 0 }}
        accept={accept}
        data-testid="upload-file-input"
      />
      <ArrowUpTrayIcon
        color={token("colors.brand.yamcha")}
        width="1rem"
        height="1rem"
      />
      <Typography variant="body" as="span" ml="s" fontWeight="semibold">
        {label}
      </Typography>
    </styled.label>
  );
}
