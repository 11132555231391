import { GqlError } from "@internal/gql/client/useGraphql";
import { APIError } from "@internal/rest/generated/schemas";

import { ErrorBanner } from "../ErrorBanner/ErrorBanner";
import { LoadingBanner } from "../LoadingBanner/LoadingBanner";

interface Props {
  error: APIError | GqlError | undefined | null;
  cover?: boolean;
}

export function StatusBanner({ error, cover = false }: Props) {
  if (error) {
    const isGqlError = Array.isArray(error);

    if (isGqlError) {
      return (
        <ErrorBanner
          fullError={error}
          correlationId={error?.[0]?.extensions?.correlation_id ?? ""}
          userMsg={error?.[0]?.message ?? ""}
        />
      );
    }

    return (
      <ErrorBanner
        fullError={error}
        correlationId={error.correlation_id ?? ""}
        userMsg={error.message ?? ""}
      />
    );
  }

  return <LoadingBanner cover={cover} />;
}
