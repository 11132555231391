import { Box, Flex, FlexProps } from "@@panda/jsx";

interface Props extends FlexProps {
  logoWidth?: string;
}

export function OdinLogo({ ...props }: Props) {
  return (
    <Flex align="center" className="logo-animation" {...props}>
      <Box position="relative" w="full" h="full">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Odin's logo"
        >
          <path
            d="M24.0004 32C28.4187 32 32.0004 28.4183 32.0004 24V8C32.0004 3.58172 28.4187 0 24.0004 0H8C3.58172 0 0 3.58172 0 8V24C0 28.4183 3.58172 32 8 32H24.0004ZM20.6297 27.6088C24.4854 27.6088 27.6111 24.4832 27.6111 20.6275C27.6111 16.7718 24.4854 13.6462 20.6297 13.6462C16.7741 13.6462 13.6484 16.7718 13.6484 20.6275C13.6484 24.4832 16.7741 27.6088 20.6297 27.6088Z"
            fill="white"
          />
        </svg>
        <Box
          w="0.875rem"
          h="0.875rem"
          borderRadius="100%"
          bgColor="grey.bunker"
          position="absolute"
          right="0.25rem"
          bottom="0.25rem"
          willChange="transform"
          transform="translate(0,0)"
          id="logo-dot"
        />
      </Box>
    </Flex>
  );
}
