import { ComboboxOption } from "./FormCombobox";

export function filterByTerm(term: string, options: ComboboxOption[]) {
  return options.filter((option) =>
    option.label.toLowerCase().includes(term.toLowerCase())
  );
}

export function getIsValidOption(term: string, options: ComboboxOption[]) {
  return Boolean(options.find((option) => option.label === term));
}

export function getOptionValue(term: string, options: ComboboxOption[]) {
  return options.find((option) => term === option.label)?.value || "";
}
