import { APIErrorWithStatus, deriveError } from "@internal/rest/client";
import { useEffect, useState } from "react";
import { toast } from "services/toast";

export function delay(timeMs = 1000) {
  return new Promise((res) => setTimeout(res, timeMs));
}

export function getOneTimeQueryConfig() {
  return {
    refetchOnWindowFocus: false,
    retry: false,
    refetchOnMount: false,
    staleTime: Infinity,
  };
}
interface HandleResourceUpdateArgs {
  action: () => Promise<unknown>;
  cleanup?: (() => Promise<unknown>) | (() => void);
  error: {
    title: string;
    message?: string;
  };
}

export async function handleResourceEffect({
  action,
  error,
  cleanup,
}: HandleResourceUpdateArgs) {
  try {
    await action();
  } catch (e: unknown) {
    const err = e as Error | APIErrorWithStatus;
    const message = formatErrMessage(err);
    const isAborted = err instanceof Error && err?.name === "AbortError";

    if (!isAborted) {
      toast.show({
        status: "error",
        title: error.title,
        desc: error.message || message,
      });
    }
  } finally {
    await cleanup?.();
  }
}

export function formatErrMessage(err: unknown) {
  const actualError = deriveError(err);

  let errMessage =
    actualError.message ||
    (err instanceof Error ? err.message : "An unexpected problem happened");

  if (actualError.correlation_id) {
    errMessage = errMessage.concat(
      `. Unique error code for tracking this problem: "${actualError.correlation_id}"`
    );
  }

  return `${errMessage}. In case the error persists contact us at hello@joinodin.com.`;
}

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
