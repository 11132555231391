/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Who covers the Odin platform fee? The leads or the whole syndicate?

 */
export type PlatformFeeType =
  (typeof PlatformFeeType)[keyof typeof PlatformFeeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PlatformFeeType = {
  syndicate_leads: "syndicate_leads",
  investors_pro_rata: "investors_pro_rata",
  investee_company: "investee_company",
  seller: "seller",
} as const;
