/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type PricePlanFlatFeeVariant =
  (typeof PricePlanFlatFeeVariant)[keyof typeof PricePlanFlatFeeVariant];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PricePlanFlatFeeVariant = {
  flat_fee: "flat_fee",
} as const;
