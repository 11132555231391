import { Form } from "components/forms/Form/Form";
import { z } from "zod";

import { CategoryTypes } from "../../utils";
import { CategoryDetail } from "../CategoryDetail/CategoryDetail";
import { FormFields } from "../CategoryDetail/utils";

export function SelfCertified() {
  return (
    <CategoryDetail
      type={CategoryTypes.self_certified}
      title="Self-certified sophisticated"
      desc="Please specify which statement applies to you. Pick the one that is most recently true."
      statements={[
        {
          text: "In the last two years, I have made two or more investments in an unlisted company.",
          question:
            "How many investments in unlisted companies have you made in the last two years?",
          placeholder: "Number of investments",
          field: (
            <Form.AmountField
              name={FormFields.Answer}
              label="How many investments in unlisted companies have you made in the last two years?"
            />
          ),
          schema: z
            .object({
              [FormFields.Answer]: z
                .string()
                .min(1, { message: "Please provide an answer" }),
            })
            .refine(
              (values) => {
                if (+values[FormFields.Answer] < 2) return false;
                return true;
              },
              {
                path: [FormFields.Answer],
                message: "Please insert an amount greater or equal to 2",
              }
            ),
        },

        {
          text: "In the last two years, I have been a member of a network or syndicate of business angels for more than 6 months, and am still a member.",
          question: "What is the name of the network/syndicate?",
          placeholder: "Name of the network/syndicate",
          field: (
            <Form.TextField
              name={FormFields.Answer}
              label="What is the name of the network/syndicate?"
            />
          ),
          schema: z.object({
            [FormFields.Answer]: z
              .string()
              .min(1, { message: "Please provide an answer" }),
          }),
        },

        {
          text: "In the last two years, I have been a director of a company with an annual turnover of at least £1 million.",
          question:
            "What is/was the name of the company and its Companies House number (or international equivalent)?",
          placeholder: "Name of the company",
          field: (
            <Form.TextField
              name={FormFields.Answer}
              label="What is/was the name of the company and its Companies House number (or international equivalent)?"
            />
          ),
          schema: z.object({
            [FormFields.Answer]: z
              .string()
              .min(1, { message: "Please provide an answer" }),
          }),
        },

        {
          text: "In the last two years, I have worked in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises.",
          question: "What is/was the name of the business/organisation?",
          placeholder: "Name of the business",
          field: (
            <Form.TextField
              name={FormFields.Answer}
              label="What is/was the name of the business/organisation?"
            />
          ),
          schema: z.object({
            [FormFields.Answer]: z
              .string()
              .min(1, { message: "Please provide an answer" }),
          }),
        },
      ]}
    />
  );
}
