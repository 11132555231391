/*
  Checks if the given value is empty.
  @param value - The value to check.
  @param ignoreZero - Whether to ignore 0 values.
*/
export function isEmpty(
  value?: string | unknown[] | number | boolean | object | null,
  ignoreZero = false
): boolean {
  if (value === undefined) return true;
  if (value === null) return true;
  if (typeof value === "object" && Object.keys(value).length === 0) return true;
  if (typeof value === "string" && value.trim().length === 0) return true;
  if (typeof value === "number" && value === 0 && !ignoreZero) return true;
  return false;
}
