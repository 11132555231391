import { HTMLStyledProps, styled } from "@@panda/jsx";
import { PropsWithChildren } from "react";

type As = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

type CustomProps = {
  variant: Variant;
  as?: As;
};

export type TypographyProps = PropsWithChildren<
  CustomProps & HTMLStyledProps<"p">
>;

const variants = {
  "heading.1": {
    fontSize: "2xl", // 42px approx
    fontWeight: "light",
    lineHeight: "normal",
  },
  "heading.2": {
    fontSize: "xl", // 26px approx
    fontWeight: "light",
    lineHeight: "normal",
  },
  "heading.3": {
    fontSize: "lg", // 20px
    fontWeight: "normal",
    lineHeight: "normal",
  },
  "heading.4": {
    fontSize: "md", // 16px
    fontWeight: "medium",
    lineHeight: "normal",
  },
  body: {
    fontSize: "sm", // 14px
    fontWeight: "normal",
    lineHeight: "25.888px",
  },
  subtext: {
    fontSize: "xs", // 12px
    fontWeight: "normal",
    lineHeight: "19.416px",
  },
};

type Variant = keyof typeof variants;

export function Typography({
  children,
  variant,
  as: outerAs,
  ...rest
}: TypographyProps) {
  const styles = variants[variant] || variants.body;

  const Element = outerAs
    ? styled[outerAs]
    : styled[variantElementMap[variant]];

  return (
    <Element {...styles} {...rest}>
      {children}
    </Element>
  );
}

const variantElementMap: Record<Variant, As> = {
  "heading.1": "h1",
  "heading.2": "h2",
  "heading.3": "h3",
  "heading.4": "h4",
  body: "p",
  subtext: "p",
};
