import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

type CurrentFieldError =
  | FieldError
  | Merge<FieldError, FieldErrorsImpl<Record<string, unknown>>>
  | undefined;

export function getFormFieldError(fieldError: CurrentFieldError) {
  return fieldError?.message?.toString() || "Invalid field";
}
