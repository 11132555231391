import { css, cva } from "@@panda/css";

// @note: Safari iOS behaves annoyingly if input text lower than 16px
export const inputFontSize = { base: "md", md: "sm" } as const;

// @note: exported as some other components should be aligned style-wise with this (.i.e comobobox)
export const inputStyles = css.raw({
  w: "full",
  bgColor: "grey.woodsmoke",
  borderColor: "grey.dune!",
  p: "s",
  borderWidth: "1px",
  borderRadius: "md",
  height: "2.5rem",
  borderStyle: "solid",
  color: "white",
  fontSize: inputFontSize,
  _placeholder: {
    color: "grey.gunsmoke",
  },
  _focus: {
    outline: "none",
    borderColor: "brand.yamcha!",
    borderStyle: "solid",
  },
  "&[aria-invalid]": {
    borderColor: "yellow.vesuvius!",
    _focus: {
      borderColor: "yellow.vesuvius!",
    },
  },
});

export const inputRecipe = cva({
  base: inputStyles,
});
