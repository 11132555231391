interface Props {
  testId?: string;
}

export function CommListIcon({ testId }: Props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testId}
    >
      <rect x="0.5" y="0.5" width="9" height="9" rx="3.5" stroke="white" />
      <circle cx="9" cy="9" r="4.5" stroke="white" />
    </svg>
  );
}
