import { css } from "@@panda/css";
import { splitCssProps, styled } from "@@panda/jsx";
import { token } from "@@panda/tokens";
import { HTMLStyledProps, RecipeVariantProps } from "@@panda/types";
import NextLink from "next/link";

import { Spinner } from "../Spinner";
import { buttonRecipe, loadingStyles } from "./styles";

type BaseProps = {
  as?: "button" | "a" | "next-link";
  isLoading?: boolean;
  isDisabled?: boolean;
  color?: string;
  testId?: string;
} & RecipeVariantProps<typeof buttonRecipe>;

type StripProps =
  | keyof BaseProps
  | "className"
  | "ref"
  | "translate"
  | "content"
  | "color"
  | "disabled";

type RawButtonProps = Omit<HTMLStyledProps<"button">, StripProps>;
type AnchorProps = Omit<HTMLStyledProps<"a">, StripProps>;

export type ButtonProps = (RawButtonProps | AnchorProps) & BaseProps;
export const buttonLoadingTestId = "button-is-loading";

export function Button({
  variant = "primary",
  size = "md",
  as = "button",
  isLoading = false,
  isDisabled = false,
  children,
  testId = "",
  ...p
}: ButtonProps) {
  const [cssProps, restProps] = splitCssProps(p);

  const className = css(
    buttonRecipe.raw({ variant, size }),
    cssProps,
    isLoading ? loadingStyles : undefined
  );

  const content = (
    <>
      {children}
      {isLoading ? (
        <Spinner
          className="button-spinner"
          data-testid={buttonLoadingTestId}
          color={variant === "primary" ? token("colors.grey.bunker") : "white"}
        />
      ) : null}
    </>
  );

  const testIdProps = testId ? { "data-testid": testId } : null;

  if (as === "a") {
    const props = restProps as AnchorProps;

    return (
      <styled.a className={className} {...props} {...testIdProps}>
        {content}
      </styled.a>
    );
  }

  if (as === "button") {
    const props = restProps as RawButtonProps;

    return (
      <styled.button
        className={className}
        type="button"
        disabled={isDisabled || isLoading}
        {...props}
        {...testIdProps}
      >
        {content}
      </styled.button>
    );
  }

  const { href = "/", target = "_self" } = restProps as AnchorProps;

  return (
    <NextLink
      href={href ?? "/"}
      target={target}
      className={className}
      {...testIdProps}
    >
      {content}
    </NextLink>
  );
}
