import { HStack, VStack } from "@@panda/jsx";
import { Radio } from "components/atoms/Radio";
import { Card } from "components/display/Card";
import { Typography } from "components/display/Typography/Typography";

export function RadioCard({
  onClick,
  value,
  option,
  isChecked,
  subText,
}: {
  onClick: () => void;
  value: string;
  option: string;
  isChecked: boolean;
  subText?: string;
}) {
  return (
    <Card
      onClick={onClick}
      role="radio"
      cursor="pointer"
      py={subText ? "0.85rem" : "1rem"}
      px={subText ? "1.1rem" : "1rem"}
      withBorder
    >
      <HStack justifyContent="flex-start" gap="m">
        <Radio
          name={value}
          value={value}
          checked={isChecked}
          onChange={onClick}
          size="md"
          hideLabel
        />
        <VStack
          alignItems="flex-start"
          textAlign="start"
          gap="0"
          position="relative"
          bottom={subText ? "0" : "1px"}
        >
          <Typography variant="body" lineHeight="1.4">
            {option}
          </Typography>
          {subText && (
            <Typography fontWeight="400!" variant="body" color="grey.gunsmoke">
              {subText}
            </Typography>
          )}
        </VStack>
      </HStack>
    </Card>
  );
}
