import { cx } from "@@panda/css";
import { Box } from "@@panda/jsx";
import { HTMLStyledProps } from "@@panda/types";
import { Styled } from "components/styled";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";
import { inputRecipe } from "./styles";

export interface FormTextFieldProps extends HTMLStyledProps<"input"> {
  name: string;
  label: string;
  subLabel?: string | React.ReactNode;
  fieldOpts?: RegisterOptions;
  errorMessage?: string;
}

export function FormTextField({
  name,
  label,
  subLabel = null,
  fieldOpts = {},
  errorMessage,
  className,
  ...rest
}: FormTextFieldProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const withError = Boolean(errors[name]);
  const isEmailInput = name === "email";
  const styles = inputRecipe();

  return (
    <Box w="full" position="relative">
      <FormLabel htmlFor={name} withSubLabel={Boolean(subLabel)}>
        {label}
      </FormLabel>

      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}

      <Styled.input
        id={name}
        autoCapitalize={isEmailInput ? "off" : "on"}
        aria-errormessage={`${name}-error`}
        className={cx(styles, className)}
        {...(withError ? { "aria-invalid": "true" } : null)}
        {...register(name, fieldOpts)}
        {...rest}
      />

      {withError ? (
        <FormError id={`${name}-error`} data-testid={`${name}-field-error`}>
          {errorMessage || getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
