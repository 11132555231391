/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Ready indicates the account is complete and all necessary checks at
the current time have been completed.

 */
export type AccountStatusReady =
  (typeof AccountStatusReady)[keyof typeof AccountStatusReady];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusReady = {
  ready: "ready",
} as const;
