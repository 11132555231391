/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Personal details are: email address, first name, last name.

 */
export type AccountStatusPersonalDetailsRequired =
  (typeof AccountStatusPersonalDetailsRequired)[keyof typeof AccountStatusPersonalDetailsRequired];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusPersonalDetailsRequired = {
  personal_details_required: "personal_details_required",
} as const;
