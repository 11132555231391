import { Typography, TypographyProps } from "../Typography/Typography";

interface Props extends Omit<TypographyProps, "variant"> {
  width?: string | string[];
  variant?: TypographyProps["variant"];
}

export function TextOverflow({
  children,
  variant = "body",
  as = "span",
  ...rest
}: Props) {
  return (
    <Typography
      textOverflow="ellipsis"
      overflow="hidden"
      whiteSpace="nowrap"
      lineHeight="short"
      variant={variant}
      as={as}
      display="block"
      {...rest}
    >
      {children}
    </Typography>
  );
}
