import { css, cx } from "@@panda/css";
import { Box, VStack, styled } from "@@panda/jsx";
import React from "react";
import { useFormContext } from "react-hook-form";

import { formLabelStyles } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";

export interface CheckboxOption {
  name: string;
  label: string;
}

interface Props {
  groupLabel: string;
  groupSubLabel?: string | React.ReactNode;
  options: CheckboxOption[];
  required?: boolean;
}

export function FormCheckboxGroup({
  groupLabel,
  groupSubLabel = null,
  options,
}: Props) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <styled.fieldset m="0" w="full" data-testid={`form-checkbox-group-${name}`}>
      <styled.legend
        className={cx(formLabelStyles, css({ mb: groupSubLabel ? "0" : "m" }))}
      >
        {groupLabel}
      </styled.legend>
      {groupSubLabel ? <FormSubLabel>{groupSubLabel}</FormSubLabel> : null}
      <Box>
        <VStack alignItems="flex-start" mb="s" _last={{ mb: "0" }}>
          {options.map((opt) => {
            const withError = Boolean(errors[opt.name]);

            return (
              <VStack
                key={opt.name}
                alignItems="flex-start"
                position="relative"
              >
                <styled.label
                  htmlFor={`${opt.name}_checkbox`}
                  display="flex"
                  cursor="pointer"
                  flexDirection="row"
                  alignItems="center"
                  userSelect="none"
                  my="xs"
                  gap="0.4rem"
                  key={opt.name}
                >
                  <input
                    type="checkbox"
                    aria-errormessage={`${name}-error`}
                    id={`${opt.name}_checkbox`}
                    data-testid={`checkbox-${opt.name}`}
                    className={css({ cursor: "pointer" })}
                    {...register(opt.name)}
                    {...(withError ? { "aria-invalid": "true" } : null)}
                  />
                  {opt.label}
                </styled.label>
              </VStack>
            );
          })}
        </VStack>
      </Box>
    </styled.fieldset>
  );
}
