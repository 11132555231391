import { cx } from "@@panda/css";
import { HTMLAttributes } from "react";

import styles from "./styles.module.css";

type Props = HTMLAttributes<HTMLInputElement> & {
  value: string;
  name: string;
  checked: boolean;
  hideLabel?: boolean;
  size?: "md" | "xl";
};

export function Radio({
  className,
  name,
  checked,
  hideLabel = false,
  size = "md",
  ...rest
}: Props) {
  return (
    <label
      className={cx(
        styles["radio"],
        size === "xl" ? styles["radio--xl"] : undefined,
        className
      )}
    >
      <input
        name={name}
        type="radio"
        checked={checked}
        aria-checked={checked}
        readOnly
        {...rest}
      />
      <span className={hideLabel ? styles["radio--no-label"] : undefined}>
        {name}
      </span>
    </label>
  );
}
