// TODO: think a better way to compute public/private pages from the path
export function isPrivatePage(pathname: string) {
  return (
    !pathname.includes("login") &&
    !pathname.includes("signup") &&
    !pathname.includes("forgot-password") &&
    !pathname.includes("register") &&
    !pathname.includes("invite") &&
    !pathname.includes("onboarding") &&
    !pathname.includes("/u/[handle]") &&
    !pathname.includes("/s/[handle]")
  );
}

export function isPublicPage(pathname: string) {
  return (
    pathname.includes("forgot-password") ||
    pathname.includes("signup") ||
    pathname.includes("login")
  );
}
