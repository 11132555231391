import { TCountryCode } from "countries-list";

import { FormCombobox, FormComboboxProps } from "../FormCombobox/FormCombobox";
import { countriesList, parseStoredCountry } from "./utils";

type Props = Omit<FormComboboxProps, "options"> & { omit?: TCountryCode[] };

export function FormCountrySelect(props: Props) {
  const {
    name,
    label,
    initialValue,
    placeholder = "Type and select your country",
    invalidErrorMessage = "Please type and select a valid country from the list",
    ...rest
  } = props;

  const initialCountry = parseStoredCountry(initialValue);

  return (
    <FormCombobox
      name={name}
      label={label}
      initialValue={initialCountry || initialValue || ""}
      placeholder={placeholder}
      options={
        props.omit?.length
          ? countriesList.filter(
              (country) => !props.omit?.includes(country.value)
            )
          : countriesList
      }
      invalidErrorMessage={invalidErrorMessage}
      {...rest}
    />
  );
}
