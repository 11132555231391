import {
  accountSubmitAppropriatenessAssessment,
  getAccountGetAppropriatenessAssessmentQueryKey,
  getAccountsGetSelfQueryKey,
  useAccountGetAppropriatenessAssessment,
} from "@internal/rest/generated/queries/accounts";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { handleResourceEffect } from "utility/async";

import { useAssessmentState } from "./useAssesmentState";

export function useAssessmentQuestions() {
  const queryClient = useQueryClient();
  const { state, handlers } = useAssessmentState();

  const [isSubmitting, setSubmitting] = useState(false);

  const appropriatenessStatusResult = useAccountGetAppropriatenessAssessment({
    query: {
      refetchOnWindowFocus: false,
    },
  });

  async function handleSubmitAssessment() {
    setSubmitting(true);

    await handleResourceEffect({
      async action() {
        const result = await accountSubmitAppropriatenessAssessment({
          question_answers: state.completedQuestions.map((answer) => ({
            answer_id: answer.answerId,
            question_id: answer.questionId,
          })),
        });

        if (result.appropriateness_assessment_status === "completed_passed") {
          await queryClient.invalidateQueries({
            queryKey: getAccountsGetSelfQueryKey(),
          });
          // will refetch account and re-render useOnboardingScreen
        }

        if (
          result.appropriateness_assessment_status === "completed_failed" ||
          result.appropriateness_assessment_status === "blocked"
        ) {
          queryClient.setQueryData(
            getAccountGetAppropriatenessAssessmentQueryKey(),
            result
          );
        }

        handlers.setIsRetryingAssessment(false);
      },
      error: {
        title: "An unexpected problem occurred",
      },
      cleanup() {
        setSubmitting(false);
      },
    });
  }

  const selectedAnswer = state.completedQuestions.find(
    (x) => x.questionId === state.activeQuestion?.id
  )?.answerId;

  return {
    isLoading: !appropriatenessStatusResult || isSubmitting,
    error: state.error,

    selectedAnswer,
    isFirstQuestion: state.questionIndex === 0,
    isLastQuestion: state.questionIndex === state.questions.length - 1,
    question: state.activeQuestion?.question_text,
    answers: state.activeQuestion?.answers,
    attempts: state.attempts,
    status: state.status,

    handleSelectAnswer: handlers.addCompletedQuestion,
    handleSubmitAssessment,
    goToNextQuestion: handlers.goToNextQuestion,
    goToPrevQuestion: handlers.goToPrevQuestion,
    handleReset: handlers.reset,
  };
}
