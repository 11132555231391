export function printSecretMessage() {
  // eslint-disable-next-line no-console
  console.log(
    `

%c
  -*=.                 .=*-
  =*.=+               +=.*=
   -# +=. :=======: .=+ #-
   :*: #%#+=+@=@+=+#%# :*:
   :*:..#+--+@-@+--+#..:*:
   -%+***####@-@####***+%-                            %@@  %@@:
    .+*****************+.                             %@@  ===.
     :#=:+#+..=..+=::=#:            :+#%%#+:    =*%%#+%@@  +##. +##-*%%#+
    :@:@:   ..@..   :@:@:          #@@*==*@@* .@@@+==#@@@  #@@. #@@%+=*@@%
   .++:@: :+++++++: :@:++.        =@@=    +@@:+@@-    #@@  #@@. #@@.   %@@.
   -%:::+%*-+***+-*%+:::%-        -@@*    #@@.=@@+    %@@  #@@. #@@    #@@.
    -%:::+#+:::::+#+:::%-          =@@@##@@%-  *@@%##@@@@  #@@. #@@    #@@.
     :#:::::::::::::::#:             :=+==:     .-=+=.-==  -==  -==    -==
      =*-:::::::::::-*=
       ++++=:::::=++++
        ...:+===+:...
             :::

                Poking around in the devtools console are we?

            Perhaps you'd like to Join the Odin engineering team 😉

https://little-wall-c1f.notion.site/Careers-at-Odin-887cb068f5af4266a8986912f1bee72f


`,
    `font-weight: bold; color: #9477F3;`
  );
}
