import { useContext } from "react";
import { ClientCategorizationContext } from "screens/onboarding/steps/CategorisationStep/context";
import { CategoryType } from "screens/onboarding/steps/CategorisationStep/utils";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

export function useCategoryList() {
  const { setActiveStep } = useContext(ClientCategorizationContext);

  const { isLgScreenUp } = useBreakpoint("lgUp");

  function onActiveCategory(type: CategoryType) {
    setActiveStep(type);
  }

  function onNoCategory() {
    setActiveStep("failure");
  }

  return {
    isLgScreenUp,
    onActiveCategory,
    onNoCategory,
  };
}
