import { css, cx } from "@@panda/css";
import { Box, HTMLStyledProps, styled } from "@@panda/jsx";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Spinner } from "components/atoms/Spinner";
import { inputStyles } from "components/forms/Form/FormTextField/styles";
import { Controller } from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";
import { FloatingPrefix } from "./FloatingPrefix";
import { StateIndicator } from "./components/StateIndicator";
import { useFormHandleField } from "./useFormHandleField";

export interface FormHandleFieldProps extends HTMLStyledProps<"input"> {
  name: string;
  label: string;
  prefix: string;
  subLabel?: string | React.ReactNode;
  validation: (v: string) => Promise<void>;
  onValidationLoading?: ((isLoading: boolean) => void) | undefined;
  errorMessage?: string;
}

export function FormHandleField({
  name,
  label,
  prefix,
  subLabel = null,
  errorMessage,
  validation,
  onValidationLoading,
  ...rest
}: FormHandleFieldProps) {
  const { errors, withError, control, value, onChange, isValidating, isValid } =
    useFormHandleField({
      name,
      onValidate: validation,
      onValidationLoading,
    });

  return (
    <Box w="full">
      <FormLabel htmlFor={name} withSubLabel={Boolean(subLabel)}>
        {label}
      </FormLabel>
      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}

      <Controller
        control={control}
        name={name}
        rules={{
          required: { value: true, message: "Please choose a username" },
        }}
        render={({ fieldState }) => (
          <Box
            mb="s"
            className={css({
              borderColor: fieldState.error ? "yellow.vesuvius" : "grey.dune",
              borderWidth: "1px",
              borderRadius: "md",
              borderStyle: "solid",
              "&:focus-within": {
                borderColor: fieldState.error
                  ? "yellow.vesuvius"
                  : "brand.yamcha",
              },
            })}
          >
            <Box position="relative" w="full">
              <FloatingPrefix>{prefix}</FloatingPrefix>
              <styled.input
                id={name}
                autoCapitalize="off"
                paddingRight="2.rem"
                onChange={onChange}
                aria-errormessage={`${name}-error`}
                value={value}
                className={cx(
                  css(inputStyles),
                  css({
                    textIndent: "8.5rem",
                    border: "none!",
                    _focus: { border: "none!" },
                  })
                )}
                {...(withError ? { "aria-invalid": "true" } : null)}
                {...rest}
              />

              {isValidating ? (
                <StateIndicator>
                  <Spinner size="1rem" />
                </StateIndicator>
              ) : null}

              {isValid ? (
                <StateIndicator data-testid="handle-check-success">
                  <CheckCircleIcon
                    height="1.25rem"
                    width="1.25rem"
                    style={{ color: "#47B47B" }}
                  />
                </StateIndicator>
              ) : null}
            </Box>
          </Box>
        )}
      />

      {withError ? (
        <FormError data-testid={`${name}-field-error`} id={`${name}-error`}>
          {errorMessage || getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
