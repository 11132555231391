import { useState } from "react";

import { AssessmentState, Step } from "./utils";

export type ActiveStep = Step;
export type AssessmentStatus = AssessmentState | "";

export function useCategorisationStep() {
  const [active, setActive] = useState<ActiveStep>("intro");

  return {
    activeStep: active,
    setActiveStep: (step: Step) => setActive(step),
  };
}
