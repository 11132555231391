import { Box, HStack, VStack, styled } from "@@panda/jsx";
import { Thumbnail } from "components/display/Thumbnail/Thumbnail";
import { Button } from "components/main/Button";

import { RenderChildrenArgs } from "../AvatarEditor";

interface Props {
  size?: "sm" | "md";
  ctaTitle?: string;
}

export function PreviewBox({
  avatarUrl,
  withError,
  size = "sm",
  ctaTitle = "Add",
  openModal,
}: RenderChildrenArgs & Props) {
  return size === "sm" ? (
    <HStack gap="m" alignItems="flex-end">
      {avatarUrl ? (
        <styled.img
          src={avatarUrl}
          width="3rem"
          height="3rem"
          bg="black"
          borderRadius="base"
          data-testid="avatar-preview"
          borderWidth="1px"
          borderStyle="solid"
          borderColor={withError ? "red.salmon" : "grey.dune"}
        />
      ) : (
        <Box
          width="3rem"
          height="3rem"
          bg="grey.woodsmoke"
          borderRadius="base"
          data-testid="avatar-preview-empty"
          borderWidth="1px"
          borderStyle="solid"
          borderColor={withError ? "yellow.vesuvius" : "grey.dune"}
        />
      )}
      <Button onClick={openModal} variant="secondary">
        {ctaTitle}
      </Button>
    </HStack>
  ) : (
    <VStack gap="m" mb="xl">
      <Thumbnail
        alt="Preview"
        src={avatarUrl}
        placeholder="company"
        width="6rem"
        height="6rem"
        circle
        {...(withError ? { borderColor: "yellow.yellowsea !important" } : null)}
      />
      <Button onClick={openModal} variant="secondary">
        {ctaTitle}
      </Button>
    </VStack>
  );
}
