import { HTMLStyledProps, StyledComponent, styled } from "@@panda/jsx";
import { ReactNode } from "react";

type BaseProps = {
  children: ReactNode;
  testId?: string;
  withBorder?: boolean;
  withHover?: boolean;
  withShadow?: boolean;
};

export type CardProps = BaseProps & HTMLStyledProps<"div">;

/**
 * @note deprecated in favour of <ContentWrapper />, <DealCard /> and <EntityCard />
 */
export function Card({
  children,
  withBorder = false,
  withHover = false,
  withShadow = false,
  onClick,
  testId,
  ...rest
}: CardProps) {
  const borderProps = withBorder
    ? {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "grey.dune !important",
      }
    : null;

  const hoverProps = withHover
    ? {
        transition: "background-color .2s ease-in-out",
        _hover: {
          bgColor: "grey.minecraft",
        },
      }
    : null;

  // We just want the types for the "base" HTML style props, we don't care specifically
  // if the rendered tag is a `button` or a `div` as is unlikely we'll use any JSX attribute
  // specific to one or the other...
  const Tag = (onClick ? styled.button : styled.div) as StyledComponent<"div">;

  return (
    <Tag
      borderRadius="xl"
      px="1.5rem"
      py="1rem"
      bgColor="grey.woodsmoke"
      width="full"
      cursor={onClick ? "pointer" : "default"}
      onClick={onClick}
      overflow="hidden"
      position="relative"
      {...(onClick ? { type: "button" } : null)}
      {...(withShadow
        ? {
            boxShadow: ` 0px 0px 206px 0px #8e8e8e52`,
          }
        : {})}
      {...hoverProps}
      {...borderProps}
      {...(testId ? { ["data-testid"]: testId } : null)}
      {...rest}
    >
      {children}
    </Tag>
  );
}
