import { css } from "@@panda/css";
import { styled } from "@@panda/jsx";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { zendesk } from "services/zendesk";

import { NavButton } from "../../NavButton/NavButton";

let expiresAt: number | null = null;

const verifyUserToZendesk = async () => {
  try {
    const result = await zendesk.verifyUser();
    if (!result) return false;

    const { decodedJwt, jwt } = result;

    if (decodedJwt?.exp) {
      expiresAt = +decodedJwt.exp;
    }

    zendesk.loginUser(jwt);

    return true;
  } catch {
    return false;
  }
};

export function GetHelpButton() {
  useEffect(() => {
    sessionStorage.setItem("ZD-widgetOpen", "false"); // force close
  }, []);

  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  const [amountOfUnreadMessages, setAmountOfUnreadMessages] = useState(0);

  useEffect(() => {
    if (scriptIsLoaded) {
      void verifyUserToZendesk();

      zendesk.onUnreadMessagesChange((count) =>
        setAmountOfUnreadMessages(count)
      );
    }
  }, [scriptIsLoaded]);

  return (
    <>
      <zendesk.Script
        onReady={() => {
          setScriptIsLoaded(true);
          if (window.zE) window.zE("messenger", "show");
        }}
      />

      <NavButton
        variant="button"
        label="Get help"
        className={css({ padding: "0.3rem!", position: "relative" })}
        onClick={async () => {
          if (window.zE) {
            const nowInSeconds = Math.floor(Date.now() / 1000);

            if (expiresAt === null || nowInSeconds > expiresAt) {
              await verifyUserToZendesk();
            }

            zendesk.toggleChatWindow();

            // to inject a custom close button, we need to wait for the iframe to load
            // this only happens after the first time the chat window is opened

            // I don't know other than than to wait for a bit
            await new Promise((resolve) => setTimeout(resolve, 500));
            zendesk.injectCustomCloseButton(); // won't run more than once
          }
        }}
      >
        <QuestionMarkCircleIcon
          strokeWidth="1px"
          color="white"
          className={css({
            width: "100%",
          })}
        />

        <styled.p
          aria-description="Unread messages notification"
          data-active={amountOfUnreadMessages > 0 ? "true" : undefined}
          className={css({
            transition: "all 0.3s ease-in-out",
            backgroundColor: "brand.yamcha",
            borderRadius: "50%",
            position: "absolute",
            top: "-5px",
            right: "-5px",
            width: "12px",
            height: "12px",
            fontSize: "5px",
            opacity: 0,
            _active: {
              opacity: 1,
            },
          })}
        />
      </NavButton>
    </>
  );
}
