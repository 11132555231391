import { accountsLogout } from "@internal/rest/generated/queries/accounts";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import { useState } from "react";
import { computeNeedsOnboarding } from "screens/onboarding/utils";
import { analytics } from "services/analytics";
import { useAuth } from "services/auth/AuthProvider";
import { zendesk } from "services/zendesk";
import { handleResourceEffect } from "utility/async";

export function useAccountMenu() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const { account } = useAuth();

  const isOnboarding = computeNeedsOnboarding(account);

  async function handleLogout() {
    await handleResourceEffect({
      async action() {
        await accountsLogout();
        queryClient.clear();
        analytics.reset();
        zendesk.logoutUser();
        await router.replace("/login");
      },
      error: {
        title: "Logout error",
      },
    });
  }

  return {
    data: {
      name: `${account?.first_name} ${account?.last_name}`,
      email: account?.email,
      handle: account?.handle,
    },
    avatar: account?.logo_url ?? "",
    isOpen,
    isOnboarding,
    setIsOpen,
    handleLogout,
  };
}
