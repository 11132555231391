/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Why the user intends to use the app.

founder - A founder of a company raising funds, that probably wants to roll up
several smaller investors into a single cap table entry.

syndicate_lead - Someone who wants to launch a syndicate on Odin to collect investments
and deploy capital.

investor - Someone who wants to find and invest in early stage companies.

 */
export type AccountSignupParamsSignupIntention =
  (typeof AccountSignupParamsSignupIntention)[keyof typeof AccountSignupParamsSignupIntention];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountSignupParamsSignupIntention = {
  founder: "founder",
  syndicate_lead: "syndicate_lead",
  investor: "investor",
} as const;
