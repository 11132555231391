import { sva } from "@@panda/css";

export const menuRecipe = sva({
  slots: ["trigger", "content", "separator", "item"],
  base: {
    trigger: {
      outline: "none",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      w: "14rem",
      // Label zIndex not working?
      zIndex: 2000,
      // Align the open content with the trigger top-left or top-right corner
      position: "relative",
      left: { base: "0", lg: "1rem" },
      right: { base: "1rem", lg: "0" },
      bottom: "0.8rem",
    },
    separator: {
      borderBottom: "1px solid",
      w: "full",
    },
    item: {
      px: "m",
      py: "s",
      outline: "none",
      cursor: "pointer",
      w: "full",
      borderRadius: "md",
    },
  },
  variants: {
    variant: {
      primary: {
        content: {
          bgColor: "grey.woodsmoke",
          border: "1px solid",
          borderColor: "#323232!",
          boxShadow:
            "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px",
          borderRadius: "md",
          animation: "fadein 300ms, scaleup 100ms",
        },
        item: {
          _hover: {
            bgColor: "black",
            "& path": {
              stroke: "brand.krilin",
            },
          },
        },
        separator: {
          borderColor: "grey.dune!",
        },
      },
    },
  },
  defaultVariants: {
    variant: "primary",
  },
});
