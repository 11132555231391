import { HStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import Slider, { SliderProps } from "rc-slider";

type Props = SliderProps & {
  label: string;
  isDisabled?: boolean;
};

export function SliderControl({
  label,
  defaultValue = [50],
  isDisabled = false,
  ...rest
}: Props) {
  return (
    <HStack
      width="95%"
      pt="s"
      justifyContent="space-between"
      alignItems="center"
      pointerEvents={isDisabled ? "none" : "auto"}
      opacity={isDisabled ? 0.3 : 1}
    >
      <Typography
        variant="subtext"
        color="grey.alabaster"
        as="span"
        display="block"
        mr="m"
        width="25%"
      >
        {label}
      </Typography>
      <Slider
        className="odin-slider" // check "slider.css"
        defaultValue={defaultValue}
        aria-label={`slider-${label.toLocaleLowerCase()}`}
        data-testid={`slider-${label.toLocaleLowerCase()}`}
        {...rest}
      />
    </HStack>
  );
}
