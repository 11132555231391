import { useWatch } from "react-hook-form";
import { urlNormalize } from "url-normalize";
import { getUrlFieldFormSchema } from "utility/forms";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

type Args = {
  name: string;
};

export function useFormURLEmbed(args: Args) {
  const rawUrl: string = useWatch({ name: args.name });
  const parsedUrl = urlNormalize(rawUrl, { sortSearch: false });

  const schema = getUrlFieldFormSchema({ required: false });
  const result = schema.safeParse(parsedUrl);
  const isValid = result.success;

  const { isLgScreenUp } = useBreakpoint("lgUp");

  return {
    url: isValid ? parsedUrl : "",
    isLgScreenUp,
  };
}
