/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type SubscriptionCadence =
  (typeof SubscriptionCadence)[keyof typeof SubscriptionCadence];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubscriptionCadence = {
  none: "none",
  yearly: "yearly",
  monthly: "monthly",
} as const;
