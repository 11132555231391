import { useAccountGetAppropriatenessAssessment } from "@internal/rest/generated/queries/accounts";
import { AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus } from "@internal/rest/generated/schemas";
import { useState } from "react";

interface CompletedQuestion {
  questionId: string;
  answerId: string;
}

type AssesmentStatus =
  AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus;

const AssessmentStatus =
  AccountAppropriatenessAssessmentAppropriatenessAssessmentStatus;

export function useAssessmentState() {
  const [activeQuestionIndx, setActiveQuestionIndx] = useState(0);
  const goToNextQuestion = () => setActiveQuestionIndx((s) => s + 1);
  const goToPrevQuestion = () => setActiveQuestionIndx((s) => s - 1);
  const resetQuestionIndx = () => setActiveQuestionIndx(0);

  const [isRetryingAssessment, setIsRetryingAssessment] = useState(false);

  const [completedQuestions, setCompletedQuestions] = useState<
    CompletedQuestion[]
  >([]);

  const { data, isSuccess, error } = useAccountGetAppropriatenessAssessment();

  const questions = data?.questions || [];
  const attempts = data?.attempts_made || 0;
  const activeQuestion = questions[activeQuestionIndx];

  const status: AssesmentStatus = (() => {
    // mostly driven by backend state but we do have some logic here in FE
    // even though status might be completed_failed we still want to allow user to retry
    // unless they have next_available_at property which indiciates a hard fail (timeout, cant retry)
    if (isRetryingAssessment) return AssessmentStatus.not_started;

    return (
      data?.appropriateness_assessment_status ?? AssessmentStatus.not_started
    );
  })();

  async function addCompletedQuestion(answerId: string) {
    if (!activeQuestion) return;
    setCompletedQuestions((prev) => {
      return [
        ...prev.filter((x) => x.questionId !== activeQuestion.id),
        { answerId, questionId: activeQuestion.id },
      ];
    });
  }

  function reset() {
    resetQuestionIndx();
    setCompletedQuestions([]);
    setIsRetryingAssessment(true);
  }

  return {
    state: {
      ready: isSuccess,
      error,
      questionIndex: activeQuestionIndx,
      completedQuestions,
      status,
      activeQuestion,
      questions,
      attempts,
    },
    handlers: {
      goToNextQuestion,
      goToPrevQuestion,
      addCompletedQuestion,
      reset,
      setIsRetryingAssessment,
    },
  };
}
