import { API_ADDRESS, WEB_ADDRESS } from "./config";

export type NodeEnv = "staging" | "production" | "test";

function getNodeEnv() {
  return process.env.NODE_ENV;
}

function getApiEnvName(): "production" | "development" {
  return API_ADDRESS === "https://api.joinodin.com"
    ? "production"
    : "development";
}

function getVersion() {
  return (
    process.env["NEXT_PUBLIC_VERSION"] ?? vercelVersionString() ?? "unknown"
  );
}

function vercelVersionString() {
  if (!process.env["NEXT_PUBLIC_VERCEL_ENV"]) {
    return undefined;
  }
  const ref = process.env["NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF"];
  const sha = process.env["NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA"];
  return `${ref}_${sha}`;
}

function getRuntime(): "browser" | "node" {
  const isBrowser =
    typeof window !== "undefined" && Boolean(window["document"]);

  return isBrowser ? "browser" : "node";
}

function getEnvironment() {
  return {
    VERSION: getVersion(),
    WEB_ADDRESS,
    API_ADDRESS,
    RUNTIME: getRuntime(),
    API_ENV: getApiEnvName(),
    NODE_ENV: getNodeEnv(),
  };
}

export const environment = {
  getEnvironment,
  getVersion,
  getRuntime,
  getApiEnvName,
  getNodeEnv,
};
