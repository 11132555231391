import { Box, BoxProps } from "@@panda/jsx";

import bg from "./bg.png";

export function TiledBg({ children, ...rest }: BoxProps) {
  return (
    <Box
      backgroundImage={`url(${bg.src})`}
      backgroundRepeat="repeat"
      backgroundSize="6%"
      {...rest}
    >
      {children}
    </Box>
  );
}
