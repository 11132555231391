/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The current status of an investor's investment in the deal. The statuses are:
- commitment_required: the investor has been invited to invest in the deal,
  but has not made a commitment or chosen an entity yet.
- terms_acceptance_required: the investor has made a commitment but has not
  yet accepted the terms of the deal.
- payment_required: the investor is ready to make payment but has not yet told
  us that they have sent their investment money.
- transfer_initiated: the investor has told us that they have initiated their
  payment. Payments can take several days to be received.
- payment_received: we have received money from the investor. This still may
  not be the full investment amount.
- completed: the investor has completed their investment in the deal - the deal
  is closed and the investment finalised.
- passed: the investor has passed on this deal and indicated that they do not
  intend to invest in it.

 */
export type DealInvestorInvestmentStatus =
  (typeof DealInvestorInvestmentStatus)[keyof typeof DealInvestorInvestmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealInvestorInvestmentStatus = {
  commitment_required: "commitment_required",
  terms_acceptance_required: "terms_acceptance_required",
  payment_required: "payment_required",
  transfer_initiated: "transfer_initiated",
  payment_received: "payment_received",
  completed: "completed",
  passed: "passed",
} as const;
