import { getVersion } from "@internal/rest/generated/queries/misc";
import { StatusCover } from "components/feedback/StatusCover/StatusCover";
import { Button } from "components/main/Button";
import { PropsWithChildren, createContext, useEffect, useState } from "react";
import { environment } from "services/environment";

type Status =
  | "init" // waiting for first API ping
  | "up" // API is available
  | "down"; // API is unavailable

export type BackendContext = {
  status: Status;
  apiVersion: string;
  webVersion: string;
};

const env = environment.getEnvironment();

export const BackendCtx = createContext<BackendContext>({
  status: "init",
  apiVersion: "",
  webVersion: env.VERSION,
});

export function PingProvider(props: PropsWithChildren) {
  const [status, setStatus] = useState<Status>("init");
  const [apiVersion, setVersion] = useState("unknown");

  const check = () =>
    getVersion()
      .then((v) => {
        setVersion(v.version ?? "unknown");
        setStatus("up");
      })
      .catch(() => {
        setStatus("down");
      });

  useEffect(() => {
    void check();
  }, []);

  if (status === "down") {
    return (
      <StatusCover
        title="We’re down for maintenance"
        tip="The Odin platform is currently undergoing some maintenance and will be back online shortly. Please reach out to our team if you have any questions."
      >
        <Button variant="secondary" as="a" href="mailto:hello@joinodin.com">
          Something wrong? Contact support
        </Button>
      </StatusCover>
    );
  }

  return (
    <BackendCtx.Provider
      value={{ status, apiVersion, webVersion: env.VERSION }}
    >
      {props.children}
    </BackendCtx.Provider>
  );
}
