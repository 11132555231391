import { useEffect } from "react";
import { toast } from "services/toast";
import { useClipboard } from "utility/hooks/useClipboard";

interface Args {
  text: string;
  onCopied?: () => void;
  toastLabel?: string;
}

export function useCopyText(args: Args) {
  const { hasCopied, onCopy } = useClipboard(args.text, {
    format: "text/plain",
  });

  useEffect(() => {
    if (hasCopied) {
      toast.show({
        status: "info",
        title: `${args.toastLabel} copied to the clipboard`,
      });
    }

    if (hasCopied) {
      args.onCopied?.();
    }
  }, [hasCopied]);

  return {
    hasCopied,
    onCopy,
  };
}
