/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The due diligence status of a legal entity. This is used to track the
progress of the due diligence process for a legal entity.

 */
export type DueDiligenceStatus =
  (typeof DueDiligenceStatus)[keyof typeof DueDiligenceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DueDiligenceStatus = {
  TO_BACK_FILL: "TO_BACK_FILL",
  INPUT_REQUIRED: "INPUT_REQUIRED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
  TEMPORARILY_REJECTED: "TEMPORARILY_REJECTED",
  PENDING_AUTOMATED: "PENDING_AUTOMATED",
  PENDING_MANUAL: "PENDING_MANUAL",
  EXPIRED: "EXPIRED",
} as const;
