import { UnauthorizedLayout } from "components/site/Layout/UnauthorizedLayout/UnauthorizedLayout";
import { PropsWithChildren } from "react";

export function StepWrap({ children }: PropsWithChildren) {
  return (
    <UnauthorizedLayout.Content maxW="35rem">
      {children}
    </UnauthorizedLayout.Content>
  );
}
