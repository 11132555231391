import { css, cx } from "@@panda/css";
import { HTMLStyledProps, styled } from "@@panda/jsx";

export const formLabelStyles = css({
  fontSize: "sm",
  fontWeight: "500",
  color: "grey.iron",
  display: "block",
});

type Props = HTMLStyledProps<"label"> & {
  withSubLabel?: boolean;
};

export function FormLabel({ children, withSubLabel = false, ...rest }: Props) {
  return (
    <styled.label
      className={cx(
        formLabelStyles,
        css({ mb: withSubLabel ? "0" : "0.75rem" })
      )}
      {...rest}
    >
      {children}
    </styled.label>
  );
}
