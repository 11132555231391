import { Box, HTMLStyledProps } from "@@panda/jsx";
import { MergeProps } from "@internal/utils/types";
import { CSSProperties, ReactNode } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";

import { FormError } from "../FormError/FormError";
import { FormLabel } from "../FormLabel/FormLabel";
import { FormSubLabel } from "../FormSubLabel/FormSubLabel";
import { getFormFieldError } from "../helpers";
import { Select, Size } from "./Select";

type CustomFormSelectProps = {
  name: string;
  label?: string;
  defaultValue?: string;
  subLabel?: string | React.ReactNode;
  fieldOpts?: RegisterOptions;
  invalidErrorMessage?: string;
  placeholder?: string;
  size?: Size;
  selectStyle?: CSSProperties;
  selectContainerStyle?: CSSProperties;
  children: ReactNode;
};

type FormSelectProps = MergeProps<
  Omit<HTMLStyledProps<"select">, "style" | "className">,
  CustomFormSelectProps
>;

export function FormSelect({
  children,
  name,
  label,
  subLabel = null,
  fieldOpts,
  invalidErrorMessage,
  size = "md",

  ...rest
}: FormSelectProps) {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const withError = Boolean(errors[name]);

  return (
    <Box width="full" position="relative">
      <FormLabel htmlFor={name} withSubLabel={Boolean(subLabel)}>
        {label}
      </FormLabel>

      {subLabel ? <FormSubLabel>{subLabel}</FormSubLabel> : null}

      <Select
        {...register(name, fieldOpts)}
        {...(withError ? { "aria-invalid": "true" } : null)}
        data-testid={`form-select-${name}`}
        size={size}
        id={name}
        {...rest}
      >
        {children}
      </Select>

      {withError ? (
        <FormError>
          {invalidErrorMessage || getFormFieldError(errors[name])}
        </FormError>
      ) : null}
    </Box>
  );
}
