/* eslint-disable no-unsafe-finally */
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useDebounce } from "utility/async";

import { transformForURL } from "./utils";

interface Args {
  name: string;
  onValidate: (v: string) => Promise<void>;
  onValidationLoading?: ((isLoading: boolean) => void) | undefined;
}

export function useFormHandleField({
  name,
  onValidate,
  onValidationLoading,
}: Args) {
  const {
    register,
    control,
    setValue,
    setError,
    clearErrors,
    watch,
    formState: { errors, defaultValues },
  } = useFormContext();

  const inputValue = (watch(name) ?? "") as string;
  const debouncedInputValue = useDebounce(inputValue, 400);

  const [isValidating, setIsValidating] = useState(false);

  const toggleIsValidating = (state: boolean) => {
    setIsValidating(state);
    onValidationLoading?.(state);
  };

  const withError = Boolean(errors[name]);

  const doValidateRef = useRef(0); // track the number of doValidate calls, ignore results if there's a newer one

  useEffect(() => {
    const doValidate = async (value: string) => {
      doValidateRef.current++;
      const current = doValidateRef.current;

      if (inputValue === defaultValues?.[name] || inputValue === "") {
        toggleIsValidating(false);
        return;
      }

      const iStaleValue = () => value !== inputValue;
      const isStaleCall = () => current !== doValidateRef.current;

      if (iStaleValue()) return;

      try {
        await onValidate(value);
      } catch {
        if (isStaleCall()) return;
        setError(name, {
          message: "This username has already been taken",
          type: "custom",
        });
      } finally {
        if (isStaleCall()) return;

        toggleIsValidating(false);
      }
    };

    void doValidate(debouncedInputValue);
  }, [debouncedInputValue, onValidate, setError, name, inputValue]);

  async function onChange(e: ChangeEvent<HTMLInputElement>) {
    const raw = e.target.value;
    const transformed = transformForURL(raw);

    clearErrors(name);
    toggleIsValidating(true);
    setValue(name, transformed);
  }

  const isValid =
    !isValidating &&
    !withError &&
    inputValue !== defaultValues?.[name] &&
    inputValue !== "";

  return {
    control,
    register,
    errors,
    withError,
    value: inputValue,
    onChange,
    isValidating,
    isValid,
  };
}
