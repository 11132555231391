import { css, cx } from "@@panda/css";
import { Qwitcher_Grypen } from "next/font/google";

import { Form } from "../Form";
import { FormTextFieldProps } from "../FormTextField/FormTextField";

const displayFont = Qwitcher_Grypen({
  weight: "400",
  subsets: ["latin"],
});

interface Props extends FormTextFieldProps {
  firstName: string;
  lastName: string;
}

export function FormSignature({
  firstName,
  lastName,
  className,
  ...rest
}: Props) {
  const accountFullName = `${firstName.trim()} ${lastName.trim()}`;
  return (
    <Form.TextField
      {...rest}
      className={cx(
        css({
          textIndent: "1rem",
        }),
        displayFont.className,
        className
      )}
      fontSize="4.5rem!"
      height="auto!"
      placeholder={accountFullName}
      fieldOpts={{
        validate: (v: string) => {
          if (!v.trim()) {
            return `Please type your full name: ${accountFullName}`;
          }

          if (v.trim().toLowerCase() !== accountFullName.trim().toLowerCase()) {
            return `Please type your full name: ${accountFullName}`;
          }

          return true;
        },
      }}
    />
  );
}
