import { css, cx } from "@@panda/css";

const commonStyles = {
  title: css({
    fontWeight: "300",
    fontSize: { base: "ld", md: "lg" },
    lineHeight: "1",
  }),
  overlay: css({
    backgroundColor: "whitewash",
    backdropFilter: "blur(8px)",
    position: "fixed",
    inset: "0",
    zIndex: "modal",
    animation: "fadein .4s ease-out",
  }),
  content: css({
    background: "grey.bunker",
    padding: "l",
    position: "fixed",
    borderRadius: "xl",
    display: "flex",
    flexDirection: "column",
    zIndex: "modal",
    width: "full",
    overflowY: "hidden!",
    willChange: "width, height",
  }),
};
export const modalStyles = {
  ...commonStyles,
  content: cx(
    commonStyles.content,
    css({
      overflowY: "scroll",
      width: "40rem",
      maxH: "95vh",
      borderRadius: "2xl!",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      animation: "fadein .2s linear",
    })
  ),
  contentShadow:
    "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px,rgba(0, 0, 0, 0.2) 0px 5px 10px,rgba(0, 0, 0, 0.4) 0px 15px 40px",
  iconWrap: css({
    position: "absolute",
    top: "0.5rem",
    right: "0.8rem",
    bgColor: "transparent",
    p: "s",
    borderRadius: "full",
    transition: "background-colour .3s ease",
    outline: "none",
    border: "none",
  }),
  icon: css({
    width: "1.3rem",
  }),
};

export const drawerStyles = {
  ...commonStyles,
  content: cx(
    commonStyles.content,
    css({
      maxHeight: "90vh",
      width: "100%",
      bottom: "0",
    })
  ),
};
