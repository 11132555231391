import { Account } from "@internal/rest/generated/schemas";

import { MixpanelEvent } from ".";

export class Local {
  register(_: Account) {
    return;
  }

  track(_e: MixpanelEvent): void {
    return;
  }

  reset() {
    return;
  }
}
