import { useState } from "react";

import { Step } from "./helpers";

export function useAssessmentStep() {
  const [activeStep, setActiveStep] = useState<Step>("overview");

  return {
    activeStep,
    setActiveStep: (step: Step) => setActiveStep(step),
  };
}
