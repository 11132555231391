/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Final declaration required indicates that the account holder hasn't
acknowledged and signed off on the final declaration.

 */
export type AccountStatusSophisticatedInvestorDeclarationRequired =
  (typeof AccountStatusSophisticatedInvestorDeclarationRequired)[keyof typeof AccountStatusSophisticatedInvestorDeclarationRequired];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusSophisticatedInvestorDeclarationRequired = {
  sophisticated_investor_declaration_required:
    "sophisticated_investor_declaration_required",
} as const;
