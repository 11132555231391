import { css } from "@@panda/css";
import { SystemStyleObject } from "@@panda/types";

export type LayoutVariant = "narrow" | "wide" | "full-width" | "unauthorized";

export const WIDE_LAYOUT_MAX_WIDTH = "64rem";
export const LAYOUT_MAX_WIDTH = "48.5rem";

interface GetLayoutPropsArgs {
  width?: string;
  css?: SystemStyleObject | undefined;
  xAxisOnly?: boolean;
}

const base = css.raw({
  position: "relative",
  left: { base: "unset", lg: "2rem" },
  mx: "auto",
  w: { base: "full", lg: "88vw" },
});

const xAxis = css.raw({
  px: { base: "l", lg: "s" },
});

const yAxis = css.raw({
  py: "xl",
});

export function getLayoutProps({
  width = WIDE_LAYOUT_MAX_WIDTH,
  css: outerCss,
  xAxisOnly,
}: GetLayoutPropsArgs = {}) {
  return {
    style: { maxWidth: width },
    className: css({
      ...base,
      ...(xAxisOnly ? xAxis : { ...xAxis, ...yAxis }),
      ...outerCss,
    }),
  } as const;
}
