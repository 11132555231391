import { Account } from "@internal/rest/generated/schemas";
import mixpanel from "mixpanel-browser";

import { MixpanelEvent } from ".";

const MIXPANEL_TOKEN = "8355d4db99d4470fb5fc2e8bd07e09f9";

export class Mixpanel {
  constructor() {
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: true,
      track_pageview: true,
      persistence: "localStorage",
    });
  }

  register(a: Account) {
    const { id, email, first_name, last_name, handle, is_admin, status } = a;

    mixpanel.identify(id);

    mixpanel.register({
      email,
      first_name,
      last_name,
      handle,
      is_admin,
      status,
    });
  }

  track(event: MixpanelEvent) {
    const { name, ...rest } = event;
    mixpanel.track(name, rest);
  }

  reset() {
    mixpanel.reset();
  }
}
