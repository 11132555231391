import { useEffect, useState } from "react";
import {
  UseFormClearErrors,
  UseFormSetError,
  UseFormSetValue,
} from "react-hook-form";

import { ComboboxOption } from "./FormCombobox";
import { filterByTerm, getIsValidOption, getOptionValue } from "./utils";

type FormError = { message?: string } | undefined;

interface Args {
  initialValue: string | undefined;
  errors: Record<string, FormError>;
  setValue: UseFormSetValue<Record<string, unknown>>;
  setError: UseFormSetError<Record<string, unknown>>;
  clearErrors: UseFormClearErrors<Record<string, unknown>>;
  options: ComboboxOption[];
  name: string;
}

const validationError = {
  type: "custom",
  message: "Please type and select a valid option from the list",
};

export function useFormCombobox({
  options,
  initialValue,
  setError,
  setValue,
  errors,
  clearErrors,
  name,
}: Args) {
  const initialSearch = initialValue || "";

  const [touched, setTouched] = useState<boolean>(false);
  const [inputTerm, setInputTerm] = useState<string>(initialSearch);
  const isValidOption = getIsValidOption(inputTerm, options);
  const results = filterByTerm(inputTerm, options);
  const shouldOpen = !isValidOption && options.length > 0;

  useEffect(() => {
    if (!touched && !initialSearch) {
      setError(name, validationError);
    }

    if (!touched && initialSearch && !inputTerm) {
      setInputTerm(initialSearch);
    }
  }, [touched, initialSearch]);

  useEffect(() => {
    if (!isValidOption && touched) {
      setError(name, validationError);
      return;
    }

    if (errors) {
      clearErrors(name);
    }

    setValue(name, getOptionValue(inputTerm, options));
  }, [inputTerm]);

  function onSearchOption(term: string) {
    if (!touched) setTouched(true);
    setInputTerm(term);
  }

  function onSelectOption(value: string) {
    setInputTerm(value);
    setValue(name, getOptionValue(value, options));
  }

  return {
    inputTerm,
    results,
    onSearchOption: (term: string) =>
      requestAnimationFrame(() => onSearchOption(term)),
    onSelectOption,
    shouldOpen,
  };
}
