import { useReducer } from "react";

function reducer(state: boolean, action?: boolean) {
  return action ?? !state;
}

export function useBooleanToggle(init?: boolean) {
  const [state, toggle] = useReducer(reducer, init ?? false);
  return [state, toggle as (newState?: boolean) => void] as const;
}
