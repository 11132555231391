const nonAlphaNumerics = /[^a-z0-9-]/g;

export function transformForURL(input: string): string {
  return (
    input
      // Force lowercase using locale transformer.
      .toLocaleLowerCase()
      // Replace all non-alphanumeric characters with a hyphen while typing.
      .replaceAll(nonAlphaNumerics, "-")
      // Always ensure only one consecutive hyphen.
      .replaceAll("--", "-")
  );
}
