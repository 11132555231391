/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The answer type depends on the category of the client. Each value of the
enumerator is prefixed with the classification type which it goes with.

 * @deprecated
 */
export type ClientCategorisationAdditionalAnswerType =
  (typeof ClientCategorisationAdditionalAnswerType)[keyof typeof ClientCategorisationAdditionalAnswerType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ClientCategorisationAdditionalAnswerType = {
  none: "none",
  self_certified__business_name: "self_certified__business_name",
  certified_sophisticated__authorised_firm:
    "certified_sophisticated__authorised_firm",
  high_net_worth__net_income: "high_net_worth__net_income",
  high_net_worth__net_assets: "high_net_worth__net_assets",
} as const;
