import { uid } from "uid";
import * as z from "zod";
import { ZodSchema } from "zod";

import { deriveError } from "./errors";

export const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

type Opts<T> = {
  schema: ZodSchema<T>;
  query?: ExtendedURLSearchParamsArg;
};

type URLSearchParamsArg = ConstructorParameters<typeof URLSearchParams>["0"];

export type ExtendedURLSearchParamsArg =
  | URLSearchParamsArg
  | Record<string, string | number | boolean | string[] | number[]>; // not allowed at the type level but actually works fine, just get turned into strings

export type APIOptions<T> = Opts<T> & RequestInit;

export async function fetchAPI<T>(
  path: string,
  opts?: APIOptions<T>
): Promise<T> {
  const params = opts?.query
    ? `?${new URLSearchParams(opts.query as URLSearchParamsArg).toString()}`
    : "";

  const withQuery = `${path}${params}`;
  const request = await buildRequest(withQuery, opts);
  const response = await fetch(request);
  const data = await parseResponse(response);

  if (!isSuccessStatus(response.status)) {
    handleError(data, response, path);
  }

  return handleResponse(data, response, path, opts?.schema);
}

export async function buildRequest(path: string, opts?: RequestInit) {
  const correlationID = uid();

  const baseUrl = process.env["NEXT_PUBLIC_API_ADDRESS"];
  const isOnServer = typeof window === "undefined";
  const url = isOnServer
    ? `${baseUrl}${path}`
    : new URL(`/asgard${path}`, location.origin);

  return new Request(url, {
    mode: "cors",
    method: (opts?.method ?? "GET").toUpperCase(),
    credentials: "include",
    ...opts,
    headers: {
      ...DEFAULT_HEADERS,
      ...opts?.headers,
      "X-Correlation-ID": correlationID,
    },
  });
}

async function parseResponse(response: Response): Promise<unknown> {
  if (isJSON(response)) return response.json();
  return response.text();
}

function handleResponse<T>(
  data: unknown,
  response: Response,
  path: string,
  schema?: z.ZodSchema<T>
): T {
  if (schema === undefined) return data as T;

  const parsed = schema.safeParse(data);
  if (!parsed.success) {
    handleError(parsed.error, response, path);
  }

  return parsed.data;
}

function handleError(raw: unknown, r: Response, path: string): never {
  throw deriveError(
    raw ?? { error: `${r.status}: ${r.statusText}` },
    r.status,
    path
  );
}

function isSuccessStatus(code: number) {
  return code >= 200 && code <= 299;
}

function isJSON(response: Response) {
  const contentType = response.headers.get("Content-Type");
  return contentType?.includes("application/json") ?? false;
}
