/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The deal status, or, which tab/group to show it in. Note that we do
not express the ownership status here related to the authenticated
account.

 */
export type DealStatus = (typeof DealStatus)[keyof typeof DealStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealStatus = {
  interest: "interest",
  draft: "draft",
  review: "review",
  live: "live",
  closing: "closing",
  closed: "closed",
} as const;
