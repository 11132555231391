/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

export type DealDocumentTypeParameter =
  (typeof DealDocumentTypeParameter)[keyof typeof DealDocumentTypeParameter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DealDocumentTypeParameter = {
  "investment-agreement": "investment-agreement",
  "pitch-deck": "pitch-deck",
} as const;
