import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { toast as sonnerToast } from "sonner";

interface Args {
  status?: "error" | "info" | "success";
  title: string;
  desc?: string;
}

function show({ status = "info", title, desc }: Args) {
  const descStyle = desc ? "sonner-toast--desc" : "";

  if (status === "info") {
    return sonnerToast(title, {
      description: desc,
      className: descStyle,
      icon: <InformationCircleIcon color="white" />,
    });
  }

  return sonnerToast[status](title, {
    description: desc,
    className: descStyle,
  });
}

export const toast = {
  ...sonnerToast,
  show,
};
