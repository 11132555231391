import { APIError } from "../generated/schemas";

export function withAbortControllerSupport(self: typeof globalThis) {
  // @see https://github.com/mo/abortcontroller-polyfill/blob/master/src/utils.js
  return (
    typeof self.Request === "function" &&
    // eslint-disable-next-line no-prototype-builtins
    self.Request.prototype.hasOwnProperty("signal") &&
    self.AbortController &&
    self.AbortSignal &&
    typeof self.AbortSignal["timeout"] === "function"
  );
}

export function isAborted(err: Error | APIError | unknown) {
  return err instanceof Error && err?.name === "AbortError";
}
