import { Box, Stack, VStack } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Form } from "components/forms/Form/Form";
import { StepWrap } from "screens/onboarding/components/StepWrap";

import { useSetPasswordStep } from "./useSetPasswordStep";
import { getNameAndLabel, schema } from "./utils";

export function SetPasswordStep() {
  const { onSubmit, isSubmitting } = useSetPasswordStep();

  return (
    <StepWrap>
      <Typography
        variant="heading.2"
        fontSize="xl"
        as="h2"
        mb="s"
        data-testid="invited-to-deal-heading"
        textAlign="center"
      >
        Set a password for your account
      </Typography>
      <Box width="full" flexDirection="column" alignItems="center">
        <Stack
          height="full"
          gap="s"
          flexDirection="column"
          justifyContent="start"
          width="full"
        >
          <VStack gap="s" alignItems="center">
            <Typography variant="body" textAlign="center">
              Try to use a mix of letters, numbers and symbols.
            </Typography>
          </VStack>
          <Form
            pt="m"
            onSubmit={onSubmit}
            config={{ schema, mode: "onTouched" }}
          >
            <Form.TextField
              {...getNameAndLabel("password")}
              placeholder="*********"
              type="password"
            />
            <Box mt="m">
              <Form.TextField
                {...getNameAndLabel("repeatPassword")}
                placeholder="*********"
                type="password"
              />
            </Box>
            <Form.Submit mt="l" width="full" isLoading={isSubmitting}>
              Continue
            </Form.Submit>
          </Form>
        </Stack>
      </Box>
    </StepWrap>
  );
}
