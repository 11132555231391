import {
  accountsSophisticatedInvestorDeclaration,
  getAccountsGetSelfQueryKey,
} from "@internal/rest/generated/queries/accounts";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "services/auth/AuthProvider";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";

import { FormValues } from "./helpers";

export const useDeclarationStep = () => {
  const [isSubmitting, toggleIsSubmitting] = useBooleanToggle(false);
  const { account } = useAuth();

  const queryClient = useQueryClient();

  const handleSubmit = async (formValues: FormValues) => {
    toggleIsSubmitting();

    await handleResourceEffect({
      async action() {
        await accountsSophisticatedInvestorDeclaration({
          signature: formValues.signature,
        });

        await queryClient.invalidateQueries({
          queryKey: getAccountsGetSelfQueryKey(),
        });
      },
      cleanup() {
        toggleIsSubmitting();
      },
      error: {
        title: "Error submitting declaration, please try again.",
      },
    });
  };

  return { handleSubmit, isSubmitting, account };
};
