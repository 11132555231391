/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * A two-letter country identifier conforming to ISO-3166-2:
- https://www.iso.org/standard/72483.html
- https://www.iso.org/obp/ui
- https://en.wikipedia.org/wiki/ISO_3166-2

 */
export type Country = (typeof Country)[keyof typeof Country];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Country = {
  afghanistan: "afghanistan",
  ålandislands: "ålandislands",
  albania: "albania",
  algeria: "algeria",
  americansamoa: "americansamoa",
  andorra: "andorra",
  angola: "angola",
  anguilla: "anguilla",
  antarctica: "antarctica",
  antiguaandbarbuda: "antiguaandbarbuda",
  argentina: "argentina",
  armenia: "armenia",
  aruba: "aruba",
  australia: "australia",
  austria: "austria",
  azerbaijan: "azerbaijan",
  bahamas: "bahamas",
  bahrain: "bahrain",
  bangladesh: "bangladesh",
  barbados: "barbados",
  belarus: "belarus",
  belgium: "belgium",
  belize: "belize",
  benin: "benin",
  bermuda: "bermuda",
  bhutan: "bhutan",
  bolivia: "bolivia",
  bonaire: "bonaire",
  bosniaandherzegovina: "bosniaandherzegovina",
  botswana: "botswana",
  bouvetisland: "bouvetisland",
  brazil: "brazil",
  britishindianoceanterritory: "britishindianoceanterritory",
  brunei: "brunei",
  bulgaria: "bulgaria",
  burkinafaso: "burkinafaso",
  burundi: "burundi",
  cambodia: "cambodia",
  cameroon: "cameroon",
  canada: "canada",
  capeverde: "capeverde",
  caribbeannetherlands: "caribbeannetherlands",
  caymanislands: "caymanislands",
  centralafricanrepublic: "centralafricanrepublic",
  chad: "chad",
  chile: "chile",
  china: "china",
  christmasisland: "christmasisland",
  "cocos(keeling)islands": "cocos(keeling)islands",
  colombia: "colombia",
  comoros: "comoros",
  republicofthecongo: "republicofthecongo",
  cookislands: "cookislands",
  costarica: "costarica",
  ivorycoast: "ivorycoast",
  croatia: "croatia",
  cuba: "cuba",
  curaçao: "curaçao",
  cyprus: "cyprus",
  czechia: "czechia",
  denmark: "denmark",
  djibouti: "djibouti",
  dominica: "dominica",
  dominicanrepublic: "dominicanrepublic",
  ecuador: "ecuador",
  egypt: "egypt",
  elsalvador: "elsalvador",
  equatorialguinea: "equatorialguinea",
  eritrea: "eritrea",
  estonia: "estonia",
  eswatini: "eswatini",
  ethiopia: "ethiopia",
  falklandislands: "falklandislands",
  faroeislands: "faroeislands",
  fiji: "fiji",
  finland: "finland",
  france: "france",
  frenchguiana: "frenchguiana",
  frenchpolynesia: "frenchpolynesia",
  frenchsouthernandantarcticlands: "frenchsouthernandantarcticlands",
  gabon: "gabon",
  gambia: "gambia",
  georgia: "georgia",
  germany: "germany",
  ghana: "ghana",
  gibraltar: "gibraltar",
  greece: "greece",
  greenland: "greenland",
  grenada: "grenada",
  guadeloupe: "guadeloupe",
  guam: "guam",
  guatemala: "guatemala",
  guernsey: "guernsey",
  guinea: "guinea",
  "guinea-bissau": "guinea-bissau",
  guyana: "guyana",
  haiti: "haiti",
  heardislandandmcdonaldislands: "heardislandandmcdonaldislands",
  honduras: "honduras",
  hongkong: "hongkong",
  hungary: "hungary",
  iceland: "iceland",
  india: "india",
  indonesia: "indonesia",
  iran: "iran",
  iraq: "iraq",
  ireland: "ireland",
  isleofman: "isleofman",
  israel: "israel",
  italy: "italy",
  jamaica: "jamaica",
  japan: "japan",
  jersey: "jersey",
  jordan: "jordan",
  kazakhstan: "kazakhstan",
  kenya: "kenya",
  kiribati: "kiribati",
  southkorea: "southkorea",
  kuwait: "kuwait",
  kyrgyzstan: "kyrgyzstan",
  laos: "laos",
  latvia: "latvia",
  lebanon: "lebanon",
  lesotho: "lesotho",
  liberia: "liberia",
  libya: "libya",
  liechtenstein: "liechtenstein",
  lithuania: "lithuania",
  luxembourg: "luxembourg",
  macau: "macau",
  madagascar: "madagascar",
  malawi: "malawi",
  malaysia: "malaysia",
  maldives: "maldives",
  mali: "mali",
  malta: "malta",
  marshallislands: "marshallislands",
  martinique: "martinique",
  mauritania: "mauritania",
  mauritius: "mauritius",
  mayotte: "mayotte",
  mexico: "mexico",
  micronesia: "micronesia",
  moldova: "moldova",
  monaco: "monaco",
  mongolia: "mongolia",
  montenegro: "montenegro",
  montserrat: "montserrat",
  morocco: "morocco",
  mozambique: "mozambique",
  myanmar: "myanmar",
  namibia: "namibia",
  nauru: "nauru",
  nepal: "nepal",
  netherlands: "netherlands",
  newcaledonia: "newcaledonia",
  newzealand: "newzealand",
  nicaragua: "nicaragua",
  niger: "niger",
  nigeria: "nigeria",
  niue: "niue",
  norfolkisland: "norfolkisland",
  northmacedonia: "northmacedonia",
  northernmarianaislands: "northernmarianaislands",
  norway: "norway",
  oman: "oman",
  pakistan: "pakistan",
  palau: "palau",
  palestine: "palestine",
  panama: "panama",
  papuanewguinea: "papuanewguinea",
  paraguay: "paraguay",
  peru: "peru",
  philippines: "philippines",
  pitcairnislands: "pitcairnislands",
  poland: "poland",
  portugal: "portugal",
  puertorico: "puertorico",
  qatar: "qatar",
  réunion: "réunion",
  romania: "romania",
  russia: "russia",
  rwanda: "rwanda",
  saintbarthélemy: "saintbarthélemy",
  sainthelenaascensionandtristandacunha:
    "sainthelenaascensionandtristandacunha",
  saintkittsandnevis: "saintkittsandnevis",
  saintlucia: "saintlucia",
  saintmartin: "saintmartin",
  saintpierreandmiquelon: "saintpierreandmiquelon",
  saintvincentandthegrenadines: "saintvincentandthegrenadines",
  samoa: "samoa",
  sanmarino: "sanmarino",
  sãotoméandpríncipe: "sãotoméandpríncipe",
  saudiarabia: "saudiarabia",
  senegal: "senegal",
  serbia: "serbia",
  seychelles: "seychelles",
  sierraleone: "sierraleone",
  singapore: "singapore",
  sintmaarten: "sintmaarten",
  slovakia: "slovakia",
  slovenia: "slovenia",
  solomonislands: "solomonislands",
  somalia: "somalia",
  southafrica: "southafrica",
  southgeorgia: "southgeorgia",
  southsudan: "southsudan",
  spain: "spain",
  srilanka: "srilanka",
  sudan: "sudan",
  suriname: "suriname",
  svalbardandjanmayen: "svalbardandjanmayen",
  sweden: "sweden",
  switzerland: "switzerland",
  switzerlandandliechtenstein: "switzerlandandliechtenstein",
  syria: "syria",
  taiwan: "taiwan",
  tajikistan: "tajikistan",
  tanzania: "tanzania",
  thailand: "thailand",
  "timor-leste": "timor-leste",
  togo: "togo",
  tokelau: "tokelau",
  tonga: "tonga",
  trinidadandtobago: "trinidadandtobago",
  tunisia: "tunisia",
  turkey: "turkey",
  turkmenistan: "turkmenistan",
  turksandcaicosislands: "turksandcaicosislands",
  tuvalu: "tuvalu",
  uganda: "uganda",
  ukraine: "ukraine",
  unitedarabemirates: "unitedarabemirates",
  unitedkingdom: "unitedkingdom",
  unitedstates: "unitedstates",
  unitedstatesminoroutlyingislands: "unitedstatesminoroutlyingislands",
  uruguay: "uruguay",
  uzbekistan: "uzbekistan",
  vanuatu: "vanuatu",
  venezuela: "venezuela",
  vietnam: "vietnam",
  britishvirginislands: "britishvirginislands",
  unitedstatesvirginislands: "unitedstatesvirginislands",
  wallisandfutuna: "wallisandfutuna",
  westernsahara: "westernsahara",
  yemen: "yemen",
  zambia: "zambia",
  zimbabwe: "zimbabwe",
  AF: "AF",
  AX: "AX",
  AL: "AL",
  DZ: "DZ",
  AS: "AS",
  AD: "AD",
  AO: "AO",
  AI: "AI",
  AQ: "AQ",
  AG: "AG",
  AR: "AR",
  AM: "AM",
  AW: "AW",
  AU: "AU",
  AT: "AT",
  AZ: "AZ",
  BS: "BS",
  BH: "BH",
  BD: "BD",
  BB: "BB",
  BY: "BY",
  BE: "BE",
  BZ: "BZ",
  BJ: "BJ",
  BM: "BM",
  BT: "BT",
  BO: "BO",
  BQ: "BQ",
  BA: "BA",
  BW: "BW",
  BV: "BV",
  BR: "BR",
  IO: "IO",
  BN: "BN",
  BG: "BG",
  BF: "BF",
  BI: "BI",
  CV: "CV",
  KH: "KH",
  CM: "CM",
  CA: "CA",
  KY: "KY",
  CF: "CF",
  TD: "TD",
  CL: "CL",
  CN: "CN",
  CX: "CX",
  CC: "CC",
  CO: "CO",
  KM: "KM",
  CG: "CG",
  CD: "CD",
  CK: "CK",
  CR: "CR",
  CI: "CI",
  HR: "HR",
  CU: "CU",
  CW: "CW",
  CY: "CY",
  CZ: "CZ",
  DK: "DK",
  DJ: "DJ",
  DM: "DM",
  DO: "DO",
  EC: "EC",
  EG: "EG",
  SV: "SV",
  GQ: "GQ",
  ER: "ER",
  EE: "EE",
  SZ: "SZ",
  ET: "ET",
  FK: "FK",
  FO: "FO",
  FJ: "FJ",
  FI: "FI",
  FR: "FR",
  GF: "GF",
  PF: "PF",
  TF: "TF",
  GA: "GA",
  GM: "GM",
  GE: "GE",
  DE: "DE",
  GH: "GH",
  GI: "GI",
  GR: "GR",
  GL: "GL",
  GD: "GD",
  GP: "GP",
  GU: "GU",
  GT: "GT",
  GG: "GG",
  GN: "GN",
  GW: "GW",
  GY: "GY",
  HT: "HT",
  HM: "HM",
  VA: "VA",
  HN: "HN",
  HK: "HK",
  HU: "HU",
  IS: "IS",
  IN: "IN",
  ID: "ID",
  IR: "IR",
  IQ: "IQ",
  IE: "IE",
  IM: "IM",
  IL: "IL",
  IT: "IT",
  JM: "JM",
  JP: "JP",
  JE: "JE",
  JO: "JO",
  KZ: "KZ",
  KE: "KE",
  KI: "KI",
  KP: "KP",
  KR: "KR",
  KW: "KW",
  KG: "KG",
  LA: "LA",
  LV: "LV",
  LB: "LB",
  LS: "LS",
  LR: "LR",
  LY: "LY",
  LI: "LI",
  LT: "LT",
  LU: "LU",
  MO: "MO",
  MG: "MG",
  MW: "MW",
  MY: "MY",
  MV: "MV",
  ML: "ML",
  MT: "MT",
  MH: "MH",
  MQ: "MQ",
  MR: "MR",
  MU: "MU",
  YT: "YT",
  MX: "MX",
  FM: "FM",
  MD: "MD",
  MC: "MC",
  MN: "MN",
  ME: "ME",
  MS: "MS",
  MA: "MA",
  MZ: "MZ",
  MM: "MM",
  NA: "NA",
  NR: "NR",
  NP: "NP",
  NL: "NL",
  NC: "NC",
  NZ: "NZ",
  NI: "NI",
  NE: "NE",
  NG: "NG",
  NU: "NU",
  NF: "NF",
  MK: "MK",
  MP: "MP",
  NO: "NO",
  OM: "OM",
  PK: "PK",
  PW: "PW",
  PS: "PS",
  PA: "PA",
  PG: "PG",
  PY: "PY",
  PE: "PE",
  PH: "PH",
  PN: "PN",
  PL: "PL",
  PT: "PT",
  PR: "PR",
  QA: "QA",
  RE: "RE",
  RO: "RO",
  RU: "RU",
  RW: "RW",
  BL: "BL",
  SH: "SH",
  KN: "KN",
  LC: "LC",
  MF: "MF",
  PM: "PM",
  VC: "VC",
  WS: "WS",
  SM: "SM",
  ST: "ST",
  SA: "SA",
  SN: "SN",
  RS: "RS",
  SC: "SC",
  SL: "SL",
  SG: "SG",
  SX: "SX",
  SK: "SK",
  SI: "SI",
  SB: "SB",
  SO: "SO",
  ZA: "ZA",
  GS: "GS",
  SS: "SS",
  ES: "ES",
  LK: "LK",
  SD: "SD",
  SR: "SR",
  SJ: "SJ",
  SE: "SE",
  CH: "CH",
  SY: "SY",
  TW: "TW",
  TJ: "TJ",
  TZ: "TZ",
  TH: "TH",
  TL: "TL",
  TG: "TG",
  TK: "TK",
  TO: "TO",
  TT: "TT",
  TN: "TN",
  TR: "TR",
  TM: "TM",
  TC: "TC",
  TV: "TV",
  UG: "UG",
  UA: "UA",
  AE: "AE",
  GB: "GB",
  US: "US",
  UM: "UM",
  UY: "UY",
  UZ: "UZ",
  VU: "VU",
  VE: "VE",
  VN: "VN",
  VG: "VG",
  VI: "VI",
  WF: "WF",
  EH: "EH",
  YE: "YE",
  ZM: "ZM",
  ZW: "ZW",
} as const;
