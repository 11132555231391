// --  https://github.com/streamich/react-use/blob/master/src/useMedia.ts
import { useEffect, useState } from "react";

const getInitialState = (query: string, _defaultState?: boolean) => {
  /*
  to match our previous implementation, we are falling back to true on the server when querying for lgUp
  https://github.com/joinodin/eylenda/blob/3a17a2cc1e01c09f7d17f1a9543e57a35ce5b0e1/src/utility/breakpoints.ts#L17
   */

  if (typeof window !== "undefined") {
    return window.matchMedia(query).matches;
  }

  return query === "(min-width: 1024px)";
};

export const useMedia = (query: string, defaultState?: boolean) => {
  const [state, setState] = useState(getInitialState(query, defaultState));

  useEffect(() => {
    let mounted = true;
    const mql = window.matchMedia(query);
    const onChange = () => {
      if (!mounted) {
        return;
      }
      setState(!!mql.matches);
    };

    mql.addEventListener("change", onChange);
    setState(mql.matches);

    return () => {
      mounted = false;
      mql.removeEventListener("change", onChange);
    };
  }, [query]);

  return state;
};
