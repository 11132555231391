import { css } from "@@panda/css";
import * as RadixSwitch from "@radix-ui/react-switch";
import { ElementRef, Ref } from "react";

export type SwitchProps = {
  inputRef?: Ref<ElementRef<typeof RadixSwitch.Root>>;
  isDisabled?: boolean;
  onChange?: (value: boolean) => void;
  onBlur?: () => void;
  name?: string;
  id?: string;
  value?: boolean;
};

export function Switch(props: SwitchProps) {
  return (
    <RadixSwitch.Root
      className={RootStyles}
      onBlur={props.onBlur}
      onCheckedChange={(e) => props?.onChange?.(e.valueOf())}
      name={props.name}
      id={props.id}
      checked={!!props.value}
      ref={props.inputRef}
      disabled={props.isDisabled}
      value={props.value ? "true" : "false"}
    >
      <RadixSwitch.Thumb className={ThumbStyles} />
    </RadixSwitch.Root>
  );
}

const RootStyles = css({
  all: "unset",

  width: 42,
  height: 25,
  borderRadius: 9999,
  position: "relative",
  boxShadow: "0 1px 5px token(colors.brand.yamcha)",

  backgroundColor: "grey.iron",
  "&[data-state=checked]": {
    backgroundColor: "brand.yamcha",
  },

  _focus: {
    boxShadow: "0 0 0 3px token(colors.brand.yamcha)",
  },

  _focusVisible: {
    boxShadow: "0 0 0 3px token(colors.brand.yamcha)",
  },

  _active: {
    boxShadow: "0 0 0 3px token(colors.brand.yamcha)",
  },

  _disabled: {
    opacity: 0.4,
    cursor: "not-allowed",
    pointerEvents: "none",
  },
});

const ThumbStyles = css({
  display: "block",
  width: 21,
  height: 21,
  backgroundColor: "white",
  borderRadius: 9999,
  boxShadow: "0 2px 2px token(colors.grey.gunsmoke)",
  transition: "transform 150ms",
  transform: "translateX(2px)",
  willChange: "transform",

  "&[data-state=checked]": {
    transform: "translateX(19px)",
  },
});
