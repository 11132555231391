/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * If the account does not have a password then it needs that before any
other pieces of information. This is the bare minimum.

 */
export type AccountStatusPasswordRequired =
  (typeof AccountStatusPasswordRequired)[keyof typeof AccountStatusPasswordRequired];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountStatusPasswordRequired = {
  password_required: "password_required",
} as const;
