import {
  getAccountsGetSelfQueryKey,
  useAccountsClientCategorisationResultSubmit,
} from "@internal/rest/generated/queries/accounts";
import { ClientCategorisationCategory } from "@internal/rest/generated/schemas";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { ClientCategorizationContext } from "screens/onboarding/steps/CategorisationStep/context";
import { CategoryType } from "screens/onboarding/steps/CategorisationStep/utils";
import { handleResourceEffect } from "utility/async";
import { useBooleanToggle } from "utility/hooks/useBooleanToggle";
import { useBreakpoint } from "utility/hooks/useBreakpoint";

import { FormValues, Statement } from "./utils";

interface Args {
  statements: Statement[];
  type: CategoryType;
}

export function useCategoryDetail({ statements, type: categoryType }: Args) {
  const queryClient = useQueryClient();
  const [active, setActive] = useState<number | undefined>();
  const [selected, setSelected] = useState<number | undefined>();
  const [isSubmitting, toggleSubmitting] = useBooleanToggle();
  const { setActiveStep: goTo } = useContext(ClientCategorizationContext);
  const { mutateAsync: submit } = useAccountsClientCategorisationResultSubmit();
  const activeStatement = active !== undefined ? statements[active] : null;

  const { isLgScreenUp } = useBreakpoint("lgUp");

  function handleSelectStatement(index: number | null) {
    setSelected(index === null ? undefined : index);
  }

  function handleSetActiveStatement() {
    if (selected === undefined) return;
    setActive(selected);
  }

  function handleResetStatements() {
    setActive(undefined);
  }

  function handleGoToCategories() {
    goTo("list");
  }

  async function onSubmit(values: FormValues) {
    if (!activeStatement) return;

    toggleSubmitting();

    await handleResourceEffect({
      async action() {
        // SafeCast: we assume `categoryType` here can only be a valid category and
        //           not "none" or "failure" or "success" given the form can only
        //           be POSTed from a category page.
        const category = categoryType as ClientCategorisationCategory;

        await submit({
          data: {
            question_value: activeStatement.question,
            answer_value: values.answer,
            client_categorisation: category,
          },
        });

        await queryClient.invalidateQueries({
          queryKey: getAccountsGetSelfQueryKey(),
        });
      },
      error: {
        title: "An unexpected account problem occurred",
      },
      cleanup() {
        toggleSubmitting();
      },
    });
  }

  return {
    selectedIndex: selected,
    isLgScreenUp,
    isSubmitting,
    activeStatement,
    handleSelectStatement,
    handleSetActiveStatement,
    handleResetStatements,
    handleGoToCategories,
    onSubmit,
  };
}
