/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * Indicates whether or not the syndicate's legal name can be changed.

 */
export type SyndicateLegalNameMutability =
  (typeof SyndicateLegalNameMutability)[keyof typeof SyndicateLegalNameMutability];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SyndicateLegalNameMutability = {
  mutable: "mutable",
  immutable_has_branded_in_review_drafts:
    "immutable_has_branded_in_review_drafts",
  immutable_has_branded_published_deals:
    "immutable_has_branded_published_deals",
} as const;
