import { sva } from "@@panda/css";
import {
  inputFontSize,
  inputStyles,
} from "components/forms/Form/FormTextField/styles";

export const comboBoxRecipe = sva({
  slots: ["wrap", "popover", "input", "option"],
  base: {
    wrap: {
      borderRadius: "md",
    },
    input: inputStyles,
    popover: {
      bgColor: "grey.minecraft",
      border: "1px solid",
      overflowX: "hidden",
      borderColor: "grey.dune!",
      borderRight: "none",
      color: "grey.gunsmoke",
      p: "0",
      borderRadius: "md",
      margin: "0",
      overflowY: "scroll",
      maxHeight: "200px",
      zIndex: "tooltip",
    },
    option: {
      bgColor: "grey.minecraft",
      listStyleType: "none",
      p: "s",
      cursor: "pointer",
      fontSize: inputFontSize,
      color: "grey.iron",
      "&[data-active-item]": {
        bgColor: "grey.bunker",
      },
    },
  },
});
