import { HTMLStyledProps } from "@@panda/types";
import { Styled } from "components/styled";

import { useAlertContext } from "../context";
import { alertStyles } from "../utils";

interface Props extends HTMLStyledProps<"span"> {
  bold?: boolean;
}

export function AlertTitle({ children, bold = true }: Props) {
  const { variant } = useAlertContext();
  const styles = alertStyles({ variant });

  return (
    <Styled.span className={styles.title} fontWeight={bold ? "bold" : "normal"}>
      {children}
    </Styled.span>
  );
}
