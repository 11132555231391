import { z } from "zod";

import { AssessmentStep } from "./steps/AssessmentStep/AssessmentStep";
import { CategorisationStep } from "./steps/CategorisationStep/CategorisationStep";
import { DeclarationStep } from "./steps/DeclarationStep/DeclarationStep";
import { InvestorDetailsStep } from "./steps/InvestorDetailsStep/InvestorDetailsStep";
import { InvestorIntentionStep } from "./steps/InvestorIntentionStep/InvestorIntentionStep";
import { SetPasswordStep } from "./steps/SetPasswordStep/SetPasswordStep";

export const Params = z.object({
  syndicate: z.string(),
  deal: z.string(),
  agreement: z.string(),
  step: z.string().optional(),
});

export enum OnboardingStepPaths {
  InvestorDetails = "investor-details",
  SetPassword = "set-password",
  Intention = "intention",
  Categorisation = "categorisation",
  Assessment = "assessment",
  Declaration = "declaration",
}

export interface StepsMapItem {
  Component: () => JSX.Element;
  stepLabel: StepLabel;
}

type StepsMap = Record<OnboardingStepPaths, StepsMapItem>;

// Order of items in this array determines the step order
export const stepLabels = [
  "About you",
  "Intention",
  "Categorisation",
  "Assessment",
  "Declaration",
] as const;

type StepLabel = (typeof stepLabels)[number];

export const stepsMap: StepsMap = {
  [OnboardingStepPaths.SetPassword]: {
    Component: SetPasswordStep,
    stepLabel: "About you",
  },
  [OnboardingStepPaths.InvestorDetails]: {
    Component: InvestorDetailsStep,
    stepLabel: "About you",
  },
  [OnboardingStepPaths.Intention]: {
    Component: InvestorIntentionStep,
    stepLabel: "Intention",
  },
  [OnboardingStepPaths.Categorisation]: {
    Component: CategorisationStep,
    stepLabel: "Categorisation",
  },
  [OnboardingStepPaths.Assessment]: {
    Component: AssessmentStep,
    stepLabel: "Assessment",
  },
  [OnboardingStepPaths.Declaration]: {
    Component: DeclarationStep,
    stepLabel: "Declaration",
  },
} as const;
