import { useEffect, useRef } from "react";

export function useUploadFile() {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    return () => {
      if (inputRef.current && inputRef.current.value) {
        inputRef.current.value = "";
      }
    };
  }, []);

  return {
    inputRef,
  };
}
