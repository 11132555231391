import { Flex } from "@@panda/jsx";
import { Typography } from "components/display/Typography/Typography";
import { Link } from "components/interaction/Link";
import { Fragment } from "react";

export interface LinkProps {
  label: string;
  href: string;
  isExternal?: boolean;
}

interface Props {
  links: LinkProps[];
}

export function Links({ links }: Props) {
  return (
    <Flex justifyContent="center" alignItems="center" my="2xl" w="full">
      {links.map(({ label, href, isExternal = false }, index) => (
        <Fragment key={label}>
          <Link href={href} isExternal={isExternal} variant="secondary">
            <Typography as="span" variant="subtext" color="grey.iron">
              {label}
            </Typography>
          </Link>
          {index < links.length - 1 ? (
            <Typography
              as="span"
              variant="subtext"
              color="grey.iron"
              px="0.25rem"
            >
              •
            </Typography>
          ) : null}
        </Fragment>
      ))}
    </Flex>
  );
}
