import { z } from "zod";

export type MergeProps<TBase, UCustom> = Omit<TBase, keyof UCustom> & UCustom;

export function exhaustiveCheck<T = unknown>(
  x: never,
  options?: { fallback: T }
) {
  return options?.fallback ?? x;
}

export type NonNullableValues<T> = {
  [Key in keyof T]: NonNullable<T[Key]>;
};

export type GetFormLabels<T> = T extends (arg: never) => { label: infer U }
  ? U
  : never;

export type LooseAutocomplete<T extends string> = T | Omit<string, T>;

/**
 * Branded types
 */
export const zBranded = {
  "YYYY-MM-DD": (msg?: string) =>
    z
      .string()
      .regex(/^\d{4}-\d{2}-\d{2}$/, msg ?? "Please enter a date")
      .brand("YYYY-MM-DD"),
};

export type Branded = CreateBrandedTypes<typeof zBranded>;

type CreateBrandedTypes<zCustom> =
  zCustom extends Record<string, () => infer U>
    ? {
        //@ts-expect-error works, but not sure how to constrain U
        [K in keyof zCustom]: z.infer<U>;
      }
    : never;
