import { TCountryCode, countries, getCountryData } from "countries-list";

const countriesList = Object.keys(countries).map((code) => {
  const countryCode = code as TCountryCode; // TS -.-
  const country = countries[countryCode];

  return {
    label: country.name,
    value: code,
  };
});

const legacyCountryMap = Object.keys(countries).reduce<Record<string, string>>(
  (acc, code) => {
    const countryCode = code as TCountryCode; // TS -.-
    const country = countries[countryCode];
    const legacyKey = country.name.toLowerCase().replace(/ /g, "");

    return {
      ...acc,
      [legacyKey]: country.name,
    };
  },
  {}
);

/**
 * @note Countries historically have been stored with the following format:
 * 
 *        United Kingdom → unitedkingdom
 *        France → france

 *       We moved away from this, and store them now by ISO code instead.
 *       To be defensive, handle the case in which a retrieved stored country
 *       is in the legacy format. Using maps for fast lookup.
 */
function parseStoredCountry(country: string | undefined) {
  if (!country) return "";

  const legacy = legacyCountryMap[country];
  if (legacy) return legacy;

  // by this point we're sure the stored country is a ISO code
  return getCountryData(country as TCountryCode).name;
}

export { countriesList, parseStoredCountry };
