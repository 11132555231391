/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Odin API
 * The Odin platform API.

 * OpenAPI spec version: 0.0.1
 */

/**
 * The type of legal entity which currently is either an individual person
or a business/company. An account may control exactly one individual and
as many businesses as they want.

In the context of investments, this is a legal entity controlled by the
account making the investment. If this is "individual" then it's the
account owner themselves, otherwise if it is "business" then it will be
a business managed by the individual.

 */
export type LegalEntityType =
  (typeof LegalEntityType)[keyof typeof LegalEntityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LegalEntityType = {
  individual: "individual",
  business: "business",
} as const;
