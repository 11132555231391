import { theme } from "styles/theme";

import { useMedia } from "./useMedia";

const { breakpoints } = theme;

export type Breakpoints =
  | `${keyof typeof breakpoints}Up`
  | `${keyof typeof breakpoints}Down`;

export const variableMap = {
  baseUp: "isBaseScreenUp",
  smUp: "isSmScreenUp",
  mdUp: "isMdScreenUp",
  lgUp: "isLgScreenUp",
  xlUp: "isXlScreenUp",

  baseDown: "isBaseScreenDown",
  smDown: "isSmScreenDown",
  mdDown: "isMdScreenDown",
  lgDown: "isLgScreenDown",
  xlDown: "isXlScreenDown",
} as const satisfies Record<Breakpoints, string>;

export const useBreakpoint = <T extends Breakpoints>(breakpoint: T) => {
  const parsed = breakpoint
    .replace("Up", "")
    .replace("Down", "") as keyof typeof breakpoints;

  const boolean = useMedia(
    `(${breakpoint.includes("Up") ? "min" : "max"}-width: ${
      breakpoints[parsed]
    })`
  );

  return { [variableMap[breakpoint]]: boolean } as {
    [K in T as (typeof variableMap)[T]]: boolean;
  };
};
